<template>
  <v-card elevation="0" color="#FFFFFF" class="card-border">
    <v-row class="pa-2">
      <v-col class="col-12 col-sm-6 pa-0">
        <v-img
          :alt="title"
          :src="imageUrl"
          :max-height="imageMaxHeight"
          :min-height="219"
          :aspect-ratio="imageAspectRadio"
          :class="{ 'card-img': $vuetify.breakpoint.mdAndUp }"
        />
      </v-col>
      <v-col
        class="col-12 col-sm-6 info-card d-flex flex-column align-start pa-0"
      >
        <v-card-title :class="cardTitleClass" class="word-break">
          {{ title }}
        </v-card-title>
        <v-card-text v-if="hasSubtitle" :class="['px-6', cardTextClass]">
          {{ subTitle }}
        </v-card-text>
        <v-card-actions
          :class="cardActionsClass"
          class="mt-auto pb-0 full-width"
        >
          <v-btn outlined block :href="linkUrl" target="_blank">
            Visit Site
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'web-item',
  props: {
    title: String,
    subTitle: String,
    imageUrl: String,
    linkUrl: String,
    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasSubtitle() {
      return !!this.subTitle;
    },

    isSmall() {
      return this.small ? '--small' : '';
    },

    cardTitleClass() {
      let className = 'card-title-tablet';
      if (this.$vuetify.breakpoint.smAndDown) {
        className = 'card-title-mobile';
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        className = 'card-title-desktop' + this.isSmall;
      }
      return className;
    },

    cardTextClass() {
      let className = 'card-text-tablet';
      if (this.$vuetify.breakpoint.smAndDown) {
        className = 'card-text-mobile';
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        className = 'card-text-desktop' + this.isSmall;
      }
      return className;
    },

    imageMaxHeight() {
      let maxHeight = 246;
      if (this.$vuetify.breakpoint.smAndDown) {
        maxHeight = 219;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        maxHeight = 348;
      }
      return maxHeight;
    },

    imageAspectRadio() {
      let ratio = 2.55;
      if (this.$vuetify.breakpoint.smAndDown) {
        ratio = 2.64;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        ratio = 1.88;
      }
      return ratio;
    },

    cardActionsClass() {
      let className = 'card-actions-tablet';
      if (this.$vuetify.breakpoint.smAndDown) {
        className = 'card-actions-mobile';
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        className = 'card-actions-desktop' + this.isSmall;
      }
      return className;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title-desktop {
  padding: 12px 12px 12px;
  @include font-size(24, 32, 600);
}

.card-title-desktop--small {
  padding: 12px 12px 12px;
  @include font-size(18, 26, 600);
}

.card-title-tablet {
  word-break: normal;
  padding: 8px 12px;
  @include font-size(24, 36, 600);
}

.card-title-mobile {
  padding-left: 0px;
  @include font-size(20, 24, 600);
}

.card-text-desktop {
  @include font-size(20, 26);
}

.card-text-desktop--small {
  @include font-size(14, 20);
}

.card-text-tablet {
  @include font-size(16, 24);
}

.card-text-mobile {
  @include font-size(16, 24, 600);
}

.card-img {
  border-radius: 4px 0 0 4px;
}

.card-actions-desktop {
  @include font-size(18, 26, 600);
}

.card-actions-desktop--small {
  padding: 0 12px;
  @include font-size(16, 24, 600);
}

.card-actions-tablet {
  padding: 0px 12px;
  @include font-size(18, 26, 600);
}

.card-actions-mobile {
  padding-left: 0px;
  @include font-size(16, 24, 600);
}

.text-decoration-none {
  text-decoration: none;
}

.card-border {
  border: 1px solid #e4e8ef !important;
  box-shadow: 0px 3px 8px rgba(88, 98, 113, 0.15) !important;
  border-radius: 4px;
  padding-left: 12px;
}

.word-break {
  word-break: normal;
}
</style>
