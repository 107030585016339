<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters class="overview">
      <v-col cols="12">
        <PDSectionTitle :title="$t('product.menu_names.overview')" />
      </v-col>
      <v-col cols="12" class="d-flex flex-wrap">
        <div v-html="data" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PDSectionTitle from './PDSectionTitle';

export default {
  name: 'PDOverview',
  components: { PDSectionTitle },
  props: {
    data: {
      type: String,
      default: '',
    },
  },
};
</script>
