<template>
  <v-container class="pa-0">
    <v-row no-gutters class="mx-n3">
      <v-col cols="12">
        <CategoryComponent
          :catalogType="catalog.type"
          :isFeatured="catalog.featured"
          :title="catalog.title"
          :items="catalog.items"
          :seeAllQueryParams="getSeeAllSearchParams(catalog)"
          displayPage="displayPage"
          showSeeAllBtn
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CategoryComponent from './CategoryComponent';

export default {
  name: 'CategoryGroupContainer',
  components: {
    CategoryComponent,
  },
  props: {
    catalog: {
      type: Object,
    },
    displayPage: {
      type: String,
      default: '',
    },
    showSeeAllBtn: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getSeeAllSearchParams(catalog) {
      return !this.showSeeAllBtn
        ? null
        : !!catalog.groupSearch
        ? catalog.groupSearch.searchParams
        : null;
    },
  },
};
</script>
