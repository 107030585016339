var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"product-details-section"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.hasModel)?[_c('h1',{staticClass:"product-details-title",attrs:{"data-cy":"product-details-title"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]:[_c('v-skeleton-loader',{staticClass:"pl-3",attrs:{"max-width":"550","type":"text"}})]],2),(_vm.rating)?[_c('v-col',{staticClass:"d-flex justify-start rating pa-0 ml-3 mb-0 mb-md-5",attrs:{"cols":"auto"}},[_c('PDRating',{attrs:{"ratings":_vm.rating}}),_c('span',{staticClass:"size14-weight700"},[_vm._v(_vm._s(_vm.rating))]),_c('span',{staticClass:"pl-1 size14-weight400"},[_vm._v(_vm._s(_vm.totalRatings))])],1)]:_vm._e(),_c('v-col',{staticClass:"page-navigation-sticky pt-5 pt-sm-0",class:{ 'push-top': _vm.hasContextFlights || _vm.$vuetify.breakpoint.lgAndUp },attrs:{"cols":"12"}},[_c('CategoryMenuBar',{attrs:{"menuItems":_vm.menuNames},on:{"click":_vm.scrollToMenu}})],1),_c('v-col',{staticClass:"wrapper",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"flex-nowrap ma-0",attrs:{"justify":"center"}},[(_vm.hasModel)?_c('v-col',{staticClass:"wrapper-content py-0",attrs:{"cols":"12","md":"8"}},[_c('v-container',{staticClass:"px-1 px-sm-0 py-0",attrs:{"fluid":""}},[(_vm.isHasHighlights)?[_c('PDHighlights',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                options: {
                  threshold: _vm.intersectThreshold,
                  rootMargin: _vm.intersectRootMargin,
                },
              }),expression:"{\n                options: {\n                  threshold: intersectThreshold,\n                  rootMargin: intersectRootMargin,\n                },\n              }"}],ref:"highlights-id",staticClass:"pt-sm-10 pb-sm-8 pt-8 pb-6",attrs:{"hasModel":_vm.hasModel,"model":_vm.model,"data":_vm.highlights,"duration":_vm.duration}}),_c('v-divider')]:_vm._e(),(_vm.description)?[_c('PDOverview',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                options: {
                  threshold: _vm.intersectThreshold,
                  rootMargin: _vm.intersectRootMargin,
                },
              }),expression:"{\n                options: {\n                  threshold: intersectThreshold,\n                  rootMargin: intersectRootMargin,\n                },\n              }"}],ref:"overview-id",staticClass:"py-sm-10 py-6",attrs:{"data":_vm.description}}),_c('v-divider')]:_vm._e(),(_vm.hasModel && this.isHasSafetyMeasures)?[_c('PDHealthSafety',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                options: {
                  threshold: _vm.intersectThreshold,
                  rootMargin: _vm.intersectRootMargin,
                },
              }),expression:"{\n                options: {\n                  threshold: intersectThreshold,\n                  rootMargin: intersectRootMargin,\n                },\n              }"}],ref:"safetyMeasures-id",staticClass:"py-sm-10 py-6",attrs:{"hasModel":_vm.hasModel,"model":_vm.model}}),_c('v-divider')]:_vm._e(),(_vm.isHasTicketsInfo)?[_c('PDTickets',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                options: {
                  threshold: _vm.intersectThreshold,
                  rootMargin: _vm.intersectRootMargin,
                },
              }),expression:"{\n                options: {\n                  threshold: intersectThreshold,\n                  rootMargin: intersectRootMargin,\n                },\n              }"}],ref:"tickets-id",staticClass:"pt-sm-10 py-6 pb-0",attrs:{"includes":_vm.includes,"excludes":_vm.excludes,"voucher":_vm.voucher,"operates":_vm.operates,"language":_vm.language,"liveGuide":_vm.liveGuide,"audioGuide":_vm.audioGuide}}),_c('v-divider')]:_vm._e(),(_vm.isHasInstructions)?[_c('PDInstructions',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                options: {
                  threshold: _vm.intersectThreshold,
                  rootMargin: _vm.intersectRootMargin,
                },
              }),expression:"{\n                options: {\n                  threshold: intersectThreshold,\n                  rootMargin: intersectRootMargin,\n                },\n              }"}],ref:"instructions-id",staticClass:"py-sm-10 py-6",attrs:{"model":_vm.model,"hasModel":_vm.hasModel,"returnDetails":_vm.returnDetails,"departurePoint":_vm.departurePoint,"startTime":_vm.startTime}}),_c('v-divider')]:_vm._e(),(_vm.isHasMoreInformation)?[_c('PDMoreInformation',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                options: {
                  threshold: _vm.intersectThreshold,
                  rootMargin: _vm.intersectRootMargin,
                },
              }),expression:"{\n                options: {\n                  threshold: intersectThreshold,\n                  rootMargin: intersectRootMargin,\n                },\n              }"}],ref:"moreInformation-id",staticClass:"py-sm-10 py-6",attrs:{"moreInformation":_vm.moreInformation}}),_c('v-divider')]:_vm._e(),(_vm.cancellationPolicy)?[_c('PDTermsConditions',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                options: {
                  threshold: _vm.intersectThreshold,
                  rootMargin: _vm.intersectRootMargin,
                },
              }),expression:"{\n                options: {\n                  threshold: intersectThreshold,\n                  rootMargin: intersectRootMargin,\n                },\n              }"}],ref:"cancellationPolicy-id",staticClass:"pt-sm-10 pt-6 pb-0",attrs:{"cancellationPolicy":_vm.cancellationPolicy}})]:_vm._e(),(_vm.getTranslation || _vm.getTiqetsAttribution)?[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.getTranslation)?[_c('v-col',{attrs:{"cols":"12"}},[_c('a',{staticClass:"translation-attribution-link",attrs:{"href":_vm.model.providerTranslationAttribution.link}},[_c('v-img',{attrs:{"alt":"providerLogo","contain":true,"max-width":"161px","height":"24px","src":_vm.model.providerTranslationAttribution.image}})],1)])]:_vm._e(),(_vm.getTiqetsAttribution)?[_c('v-col',{staticClass:"d-flex flex-row",attrs:{"cols":"12"}},[_c('span',{staticClass:"mr-2 size14-weight700"},[_vm._v(" "+_vm._s(_vm.model.supplier.trademark.copyRight)+" ")]),_c('v-img',{attrs:{"alt":"trademarkLogo","contain":true,"max-width":"161px","height":"24px","src":_vm.model.supplier.trademark.iconUrl,"position":"left"}})],1)]:_vm._e()],2)]:_vm._e()],2)],1):_vm._e(),_c('v-col',{staticClass:"d-none d-md-flex",attrs:{"cols":"4"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"wrapper-cta"},[_c('OptionsCtaCardContainer'),_c('div',{staticClass:"d-md-flex wrapper-cta-auth"},[_c('SignInCtaCardContainer')],1)],1)])],1)],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }