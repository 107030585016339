<template>
  <v-container fluid class="pa-0 pb-sm-4 pb-0">
    <v-row no-gutters class="tickets">
      <v-col cols="12">
        <PDSectionTitle :title="$t('product.menu_names.tickets')" />
      </v-col>
      <template v-if="includes || excludes">
        <v-col cols="12" class="mb-6">
          <h3 class="tickets-subblockTitle mb-3">
            {{ $t('booking.what_expect') }}
          </h3>
          <v-row no-gutters>
            <template v-if="includes">
              <v-col cols="12" sm="6" class="includes pb-3 pb-sm-0">
                <vue-markdown :source="includes" />
              </v-col>
            </template>
            <template v-if="excludes">
              <v-col cols="12" sm="6" class="excludes">
                <vue-markdown :source="excludes" />
              </v-col>
            </template>
          </v-row>
        </v-col>
      </template>

      <template v-if="voucher">
        <v-col cols="12" class="mb-6">
          <PDSectionTextBlock
            :hideDivider="true"
            :title="$t('booking.tickets.voucher_option')"
            :text="voucher"
          />
        </v-col>
      </template>

      <template v-if="operates">
        <v-col cols="12" class="mb-6">
          <PDSectionTextBlock
            :hideDivider="true"
            :title="$t('booking.tickets.operates')"
            :text="operates"
          />
        </v-col>
      </template>

      <template v-if="language">
        <v-col cols="12" class="mb-6">
          <PDSectionTextBlock
            :hideDivider="true"
            :title="$t('booking.tickets.languages')"
            :text="language"
          />
        </v-col>
      </template>

      <template v-if="liveGuide">
        <v-col cols="12" class="mb-6">
          <PDSectionTextBlock
            :hideDivider="true"
            :title="$t('booking.tickets.live_guide_languages')"
            :text="liveGuide"
          />
        </v-col>
      </template>

      <template v-if="audioGuide">
        <v-col cols="12">
          <PDSectionTextBlock
            :hideDivider="true"
            :title="$t('booking.tickets.live_guide_languages')"
            :text="audioGuide"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import PDSectionTextBlock from './PDSectionTextBlock';
import PDSectionTitle from './PDSectionTitle';

export default {
  name: 'PDTickets',
  components: { PDSectionTitle, PDSectionTextBlock },
  props: {
    includes: {
      type: String,
      default: '',
    },
    voucher: {
      type: String,
      default: '',
    },
    operates: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      default: '',
    },
    liveGuide: {
      type: String,
      default: '',
    },
    audioGuide: {
      type: String,
      default: '',
    },
    excludes: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.tickets-subblockTitle {
  color: var(--v-grey8-base);
  @include font-size(16, 130, 500);
}
</style>
