<script>
import ProductBookingMixins from '../booking/mixins/ProductBookingMixins.vue';

export default {
  name: 'QuestionsMixins',
  mixins: [ProductBookingMixins],
  methods: {
    loadQuestions({ passIds }) {
      this.$store.dispatch(`${this.storeModuleName}/loadQuestions`, {
        productId: this.productId,
        passIds,
      });
    },
    clearQuestions() {
      this.$store.dispatch(`${this.storeModuleName}/clearProductQuestions`);
    },
  },
  computed: {
    productQuestions() {
      return this.$store.state[this.storeModuleName].questions;
    },
  },
};
</script>
