<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col no-gutters>
        <transition name="bounceInRight">
          <template v-if="hasDate">
            <v-row no-gutters>
              <div class="icon-wrapper">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="
                  M5.66667 4.83333V1.5V4.83333ZM12.3333 4.83333V1.5V4.83333ZM4.83333
                  8.16667H13.1667H4.83333ZM3.16667 16.5H14.8333C15.2754 16.5 15.6993
                  16.3244 16.0118 16.0118C16.3244 15.6993 16.5 15.2754 16.5 14.8333V4.83333C16.5
                  4.39131 16.3244 3.96738 16.0118 3.65482C15.6993 3.34226 15.2754 3.16667 14.8333
                  3.16667H3.16667C2.72464 3.16667 2.30072 3.34226 1.98816 3.65482C1.67559 3.96738
                  1.5 4.39131 1.5 4.83333V14.8333C1.5 15.2754 1.67559 15.6993 1.98816 16.0118C2.30072
                  16.3244 2.72464 16.5 3.16667 16.5Z
                "
                    stroke="#717171"
                    stroke-width="1.56"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="selection-label px-3 pb-2">{{ selectedDate }}</div>
            </v-row>
          </template>
        </transition>
        <transition name="bounceInRight">
          <template v-if="hasTicket">
            <v-row no-gutters class="flex-nowrap">
              <div class="icon-wrapper">
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="
                  M11.5 1.16602V2.83268V1.16602ZM11.5 6.16602V7.83268V6.16602ZM11.5
                  11.166V12.8327V11.166ZM3.16667 1.16602C2.72464 1.16602 2.30072 1.34161
                  1.98816 1.65417C1.67559 1.96673 1.5 2.39065 1.5 2.83268V5.33268C1.94203
                  5.33268 2.36595 5.50828 2.67851 5.82084C2.99107 6.1334 3.16667 6.55732
                  3.16667 6.99935C3.16667 7.44138 2.99107 7.8653 2.67851 8.17786C2.36595
                  8.49042 1.94203 8.66602 1.5 8.66602V11.166C1.5 11.608 1.67559 12.032
                  1.98816 12.3445C2.30072 12.6571 2.72464 12.8327 3.16667 12.8327H14.8333C15.2754
                  12.8327 15.6993 12.6571 16.0118 12.3445C16.3244 12.032 16.5 11.608 16.5
                  11.166V8.66602C16.058 8.66602 15.634 8.49042 15.3215 8.17786C15.0089
                  7.8653 14.8333 7.44138 14.8333 6.99935C14.8333 6.55732 15.0089 6.1334
                  15.3215 5.82084C15.634 5.50828 16.058 5.33268 16.5 5.33268V2.83268C16.5
                  2.39065 16.3244 1.96673 16.0118 1.65417C15.6993 1.34161 15.2754 1.16602
                  14.8333 1.16602H3.16667Z
                "
                    stroke="#717171"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="selection-label px-3 pb-2">{{ ticket }}</div>
            </v-row>
          </template>
        </transition>
        <transition name="bounceInRight">
          <template v-if="hasPerson">
            <v-row no-gutters>
              <div class="icon-wrapper">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="
                  M9 2.62833C9.44787 2.12059 10.0397 1.76126 10.6968 1.59816C11.354 1.43507
                  12.0451 1.47594 12.6785 1.71533C13.3118 1.95473 13.8572 2.38131 14.2421
                  2.9383C14.627 3.49529 14.8332 4.15629 14.8332 4.83333C14.8332 5.51038
                  14.627 6.17138 14.2421 6.72837C13.8572 7.28536 13.3118 7.71193 12.6785
                  7.95133C12.0451 8.19073 11.354 8.2316 10.6968 8.06851C10.0397 7.90541
                  .44787 7.54608 9 7.03833M11.5 16.5H1.5V15.6667C1.5 14.3406 2.02678 13.0688
                  2.96447 12.1311C3.90215 11.1935 5.17392 10.6667 6.5 10.6667C7.82608 10.6667
                  9.09785 11.1935 10.0355 12.1311C10.9732 13.0688 11.5 14.3406 11.5 15.6667V16.5ZM11.5
                  16.5H16.5V15.6667C16.5001 14.7889 16.2692 13.9266 15.8304 13.1664C15.3916
                  12.4062 14.7604 11.7749 14.0003 11.336C13.2402 10.8971 12.3779 10.666 11.5002
                  10.666C10.6224 10.6659 9.76015 10.897 9 11.3358M9.83333 4.83333C9.83333
                  5.71739 9.48214 6.56524 8.85702 7.19036C8.2319 7.81548 7.38405 8.16667 6.5
                  8.16667C5.61594 8.16667 4.7681 7.81548 4.14298 7.19036C3.51786 6.56524 3.16667
                  5.71739 3.16667 4.83333C3.16667 3.94928 3.51786 3.10143 4.14298 2.47631C4.7681
                  1.85119 5.61594 1.5 6.5 1.5C7.38405 1.5 8.2319 1.85119 8.85702 2.47631C9.48214
                  3.10143 9.83333 3.94928 9.83333 4.83333Z
                "
                    stroke="#717171"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <div class="selection-label px-3">{{ person }}</div>
            </v-row>
          </template>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OrderSelections',
  props: {
    selectedDate: {
      type: String,
      default: 'Tuesday, December 10',
    },
    ticket: {
      type: String,
      default: 'English . 1130',
    },
    person: {
      type: String,
      default: '4 people',
    },
  },

  methods: {
    isEmptyOrNull(val) {
      return val === null || val === '';
    },
  },

  computed: {
    hasDate() {
      return !this.isEmptyOrNull(this.selectedDate);
    },

    hasTicket() {
      return !this.isEmptyOrNull(this.ticket);
    },

    hasPerson() {
      return !this.isEmptyOrNull(this.person);
    },
  },
};
</script>

<style lang="scss" scoped>
.bounceInRight-enter-active {
  animation: bounceInRight 0.5s;
}
.bounceInRight-leave-active {
  animation: bounceInRight 0.5s reverse;
}
.icon-wrapper {
  display: flex;
  width: 17px;
  height: 15px;
  padding-top: 4px;
}

.selection {
  &-label {
    @include font-size(16, 150, 400);
  }
}
</style>
