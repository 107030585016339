<script>
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins.vue';
export default {
  name: 'ProductOrderMixins',
  mixins: [ProductCheckoutMixins],
  created() {
    this.init();
  },
  data() {
    return {
      localProductOrderId: null,
    };
  },
  methods: {
    async init() {
      this.localProductOrderId = await this.prepareLocalOrder();
    },
    async prepareLocalOrder() {
      const { localOrderId } = await this.$store.dispatch(
        'product/prepareOrder',
        null,
        {
          root: true,
        }
      );
      return localOrderId;
    },
    setOrderProductId(productId) {
      this.$store.dispatch(
        'product/setOrderProductId',
        {
          id: this.mLocalOrderId,
          productId,
        },
        {
          root: true,
        }
      );
    },
    async createOrder(orderRequestObject) {
      if (!this.hasLocalOrder) {
        await this.init();
      }
      this.$store.dispatch(
        'product/createOrderRequest',
        {
          id: this.mLocalOrderId,
          order: orderRequestObject,
        },
        {
          root: true,
        }
      );
    },
    checkOutOrder({ orderId, paymentMethodId, discountToken }) {
      this.$store.dispatch(
        'product/checkOutOrder',
        {
          id: this.mLocalOrderId,
          orderId,
          paymentMethodId,
          discountToken,
        },
        {
          root: true,
        }
      );
    },
    clearOrderState() {
      this.clearOrderCreationState();
      this.clearOrderCheckOutState();
    },
    clearOrderCheckOutState() {
      this.$store.dispatch(
        'product/clearOrderCheckout',
        {
          id: this.mLocalOrderId,
        },
        {
          root: true,
        }
      );
    },
    clearOrderCreationState() {
      this.$store.dispatch(
        'product/clearOrderCreation',
        {
          id: this.mLocalOrderId,
        },
        {
          root: true,
        }
      );
    },
  },
  computed: {
    hasLocalOrder() {
      return this.localProductOrderId != null;
    },
    mLocalOrderId() {
      return this.localProductOrderId;
    },
  },
};
</script>
