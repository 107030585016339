<template>
  <h2 class="section-title">
    {{ title }}
  </h2>
</template>

<script>
export default {
  name: 'PDSectionTitle',
  props: {
    title: {
      type: String,
      default: function() {
        return this.$t('product.details_sections.section_title');
      },
    },
  },
};
</script>

<style scoped lang="scss">
.section-title {
  position: relative;
  color: var(--v-grey8-base);
  margin-bottom: 24px;
  @include font-size(20, 135, 500);

  @media (min-width: map-get($grid-breakpoints, sm)) {
    @include font-size(24, 125, 500);
  }
}
</style>
