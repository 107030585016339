<template>
  <v-container class="product-details" fluid color="transparent">
    <template v-if="hasNotFoundError">
      <ErrorPage
        link="/discover"
        :button-text="
          $t('product.explore', {
            name: $t('welcome.FindExperiencesDescription'),
          })
        "
      >
        <p class="error-code">
          {{ $t('product.product_not_found') }}
        </p>
        <p class="error-text">
          {{ $t('product.product_not_found_description') }}
        </p>
      </ErrorPage>
    </template>
    <template v-else>
      <FlightAlert ref="FlightAlert" isDetailsPage :link-text="getLinkText" />
      <v-row class="header-section" justify="space-around">
        <v-col align-self="center" cols="12">
          <ImageCarousel
            :imgElevation="0"
            imgDistance="4"
            :imageUrls="imageUrls"
          />
        </v-col>
      </v-row>
      <ProductDetailsSection :model="model" />
      <v-row no-gutters class="wrapper-cta mx-n3 d-flex d-md-none">
        <v-col cols="12">
          <SignInCtaCardContainer />
          <template v-if="renderComponent">
            <OptionsCtaCardContainer />
          </template>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import ErrorPage from '@/modules/exception/ErrorPage';
import ProductDetailsSection from './ProductDetailsSection.vue';
import ImageCarousel from '@/modules/product/features/Details/ImageCarousel';
import ProductDetailsSafetyMeasuresMixins from '@/modules/product/booking/mixins/ProductDetailsSafetyMeasuresMixins';
import OptionsCtaCardContainer from './OptionsCtaCardContainer';
import SignInCtaCardContainer from '@/modules/product/booking/features/Details/SignInCtaCardContainer';
import FlightAlert from '@/core/components/FlightAlert';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import OptionsBookingCtaCardMixin from '@/modules/common/mixins/OptionsBookingCtaCardMixin';
import { mapboxService } from '@/core/services/MapboxService';

export default {
  name: 'product-details',
  components: {
    ErrorPage,
    ProductDetailsSection,
    ImageCarousel,
    OptionsCtaCardContainer,
    SignInCtaCardContainer,
    FlightAlert,
  },
  mixins: [
    ProductDetailsSafetyMeasuresMixins,
    SelectedContextFlightMixins,
    OptionsBookingCtaCardMixin,
  ],
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    isLoading: Boolean,
    error: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    hasModel() {
      return this.model !== null && this.model !== undefined;
    },

    hasNotFoundError() {
      return this.error && this.error.status >= 400;
    },

    imageUrls() {
      if (this.hasModel && this.model.imageUrls) {
        return this.model.imageUrls.map((x) => this.imageEnhancer(x));
      }
      return [require('@/assets/placeholder-image.jpg')];
    },

    getLinkText() {
      return this.$t('booking.search_experiences', {
        city: this.selectedContextFlight?.destination.city,
      });
    },
  },

  methods: {
    imageEnhancer(value = '') {
      return value.split('?')[0];
    },

    async getAutoSearchLink() {
      const query = await this.updateSelectedCity(
        this.selectedContextFlight.destination.city
      );
      const itemFromQuery = {
        q: this.$route.query?.q || '',
        longLat: [
          this.$route.query?.lng || null,
          this.$route.query?.lat || null,
        ],
        city: this.$route.query?.city || null,
      };
      const routeName =
        this.searchQuery === ''
          ? 'discoveryWithCity'
          : 'discoverySearchResults';

      let queryObject = {};

      if (
        itemFromQuery.q !== this.searchQuery ||
        (!!query && itemFromQuery.city !== query.city)
      ) {
        if (this.searchQuery) queryObject.q = this.searchQuery;
        queryObject.city = !!query ? query.city : this.$route.query.city;
        queryObject.lng = !!query ? query.longLat[0] : this.$route.query.lng;
        queryObject.lat = !!query ? query.longLat[1] : this.$route.query.lat;
      } else if (!Boolean(this.selectedItemValue.city)) {
        this.updateSelectedCity(this.selectedContextFlight?.destination.city);
        queryObject.city = this.selectedContextFlight?.destination.city;
        queryObject.lng = this.selectedContextFlight?.destination.longitude;
        queryObject.lat = this.selectedContextFlight?.destination.latitude;
      }
      return {
        link: routeName,
        params: this.$route.params,
        query: queryObject,
      };
    },

    async updateSelectedCity(city) {
      if (this.$route.query.country) {
        city += ` ${this.$route.query.country}`;
      }
      const res = await mapboxService.getCities(city);
      return res.features && res.features.length > 0
        ? {
            city: res.features[0].place_name_en,
            longLat: res.features[0].center,
          }
        : {
            city: null,
            longLat: null,
          };
    },

    redirectToErrorPage() {
      const internalServerError = this.error && this.error.status >= 500;

      if (internalServerError) this.$router.replace('/500');
    },
  },

  watch: {
    model() {
      this.forceRerender();
    },

    async error(val, oldVal) {
      if (val !== oldVal) {
        this.redirectToErrorPage();
      }
    },

    selectedContextFlight(val, oldVal) {
      if (val && oldVal && val.destination.city !== oldVal.destination.city) {
        this.$refs.FlightAlert.showAlert('discoveryWithCity');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-cta {
  max-width: 100%;
  position: fixed;
  bottom: -1px;
  width: 100%;
  background: #fff;
  z-index: 99;
}

.header-section {
  overflow: hidden;
}

.error {
  &-code {
    @include font-size(36, 130, 500);
    color: #000;
    margin-bottom: 17px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-bottom: 9px;
    }
  }

  &-text {
    @include font-size(18, 130, 400);
    margin-bottom: 27px;
  }
}
</style>
