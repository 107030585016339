<template>
  <v-sheet class="options-cta-card" data-cy="options-cta-card">
    <v-container class="pa-0 options-cta-header">
      <v-row class="pa-5 ma-0">
        <v-col cols="12" class="pa-0">
          <span class="product-price">
            {{ price.value | currency(price.baseCurrency) }}
            {{ price.baseCurrency }}
          </span>
        </v-col>
        <v-col cols="12" class="pa-0">
          <template v-if="loading">
            <v-skeleton-loader max-width="550" type="text" />
          </template>

          <template v-else>
            <span class="per-person">
              {{ $t('booking.from_price_per', { productType }) }}
            </span>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <v-divider />
    <v-container class="options-progress px-5">
      <v-row no-gutters>
        <v-col cols="12">
          <ProgressIndicatorDetailsWrapper :steps="steps" />
        </v-col>
      </v-row>
    </v-container>

    <template v-if="currentStep.id == 1">
      <v-container class="pa-0 pb-5">
        <v-row no-gutters>
          <v-col cols="12">
            <OptionsDatePicker
              v-if="renderComponent"
              :loading="productAvailability.isLoading"
              @availabilityDateSelected="availabilityDateSelected"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-if="currentStep.id == 2">
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <OptionsList
              :current="currentSelectedOptionId"
              :selected="selected"
              :list="availabilityOptionsByDate"
              @selectItem="selectItem"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-if="currentStep.id == 3">
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <template v-if="passError && getDetails.minUnits !== null">
              <div class="error-message px-5">
                {{ $t('booking.total_passengers_error', { maximumPasses }) }}
              </div>
            </template>
            <PassTypesWidget
              :passes="convertedPasses"
              :isLoading="productPasses.isLoading"
              :error="productPasses.error"
              :minTravelers="getMinTravelers"
              @passChange="passesSelected($event)"
              typePass="pass"
              :passError="passError"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-divider />
    <v-container class="px-5 py-6">
      <OptionsSteps
        :currentStep="currentStep"
        :selectedDate="selectedDate"
        :orderItems="orderItems"
        :selectedOption="selectedOption"
        @goToOptionsStep="goTo(1)"
        @goToDateStep="goTo(0)"
      />
    </v-container>
    <v-container class="pa-0 sticky">
      <v-row no-gutters>
        <v-col>
          <v-btn
            tile
            :disabled="disabledButton"
            color="primary"
            @click="buttonAction"
            block
            data-cy="select-booking-option"
            x-large
            class="select-booking-option"
          >
            <template v-if="currentStep.id == 3">
              {{ (buttonText ? buttonText : 0) | currency(amount.currency) }}
              - {{ $t('booking.book_this_tour') }}
            </template>
            <template v-else>
              {{ buttonText }}
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import OptionsBookingCtaCardMixin from '@/modules/common/mixins/OptionsBookingCtaCardMixin';
import ProgressIndicatorDetailsWrapper from '@/modules/product/features/ProgressIndicator/ProgressIndicatorDetailsWrapper.vue';
import PassTypesWidget from '@/modules/product/features/PassTypesWidget';
import OptionsDatePicker from '../../../common/components/OptionsComponents/OptionsDatePicker';
import OptionsList from '../../../common/components/OptionsComponents/OptionsList';
import OptionsSteps from '../../../common/components/OptionsComponents/OptionsSteps';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';

export default {
  name: 'OptionsBookingCtaCard',
  mixins: [
    OptionsBookingCtaCardMixin,
    SelectedContextFlightMixins,
    FlightsMixins,
  ],
  components: {
    ProgressIndicatorDetailsWrapper,
    PassTypesWidget,
    OptionsDatePicker,
    OptionsList,
    OptionsSteps,
  },

  props: {
    loading: {
      default: true,
      type: Boolean,
    },
    price: {
      default: () => ({ value: 200, baseCurrency: 'USD' }),
      type: Object,
    },
    productType: {
      default: 'person',
      type: String,
    },
  },

  methods: {
    selectOptionClick() {
      this.$emit('select-option-click');
    },
  },

  watch: {
    selectedContextFlight() {
      this.forceRerender();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.options {
  &-progress {
    padding-top: 19px;
    padding-bottom: 7px;
  }

  &-cta-header {
    background: var(--v-grey1-base);
  }

  &-cta-card {
    margin-top: 10px;
    margin-left: auto;
    width: 100%;
    max-width: 360px;
    max-height: 750px;
    background: transparent;
    border-radius: 8px;
    border-color: #e8e6e1;
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.5em;
      background: rgb(220, 220, 220);
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(169, 169, 169);
      border-radius: 5em;
    }

    .v-divider {
      border-width: 1px;
    }

    .per-person {
      color: var(--v-grey7-base);
      @include font-size(14, 150, 700);
    }

    .primary {
      fill: var(--v-primary-base);
    }
  }

  &-passes {
    &-total {
      padding-top: 10px;
      color: var(--v-grey8-base);
    }
  }
}

.error-message {
  @include font-size(14, 150);
  color: #ff4d4f;
}
.sticky {
  position: sticky;
  bottom: 0;
  background-color: #f5f5f5 !important;
}

.product-price {
  @include font-size(24, 150, 700);
}

.select-booking-option {
  @include font-size(20, 150, 700);
}
</style>
