<script>
import ProductBookingMixins from './ProductBookingMixins.vue';

export default {
  name: 'ProductOrderInformationMixins',
  mixins: [ProductBookingMixins],
  methods: {
    selectAvailabilityDate({ availabilityDate }) {
      this.$store.dispatch(
        `${this.storeModuleName}/selectOrderAvailabilityDate`,
        {
          availabilityDate: availabilityDate,
        }
      );
    },

    selectOption({ option }) {
      this.$store.dispatch(`${this.storeModuleName}/selectOrderOption`, {
        option,
      });
    },

    answerQuestions(answers) {
      this.$store.dispatch(`${this.storeModuleName}/answerQuestions`, {
        answers: answers,
      });
    },

    setTravelers(travelers) {
      this.$store.dispatch(`${this.storeModuleName}/setTravelers`, travelers);
    },

    selectPass(pass) {
      this.$store.dispatch(`${this.storeModuleName}/storePassSelection`, {
        id: pass.id,
        value: pass.value,
      });
      this.passesReady(false);
      this.clearQuestions();
    },

    passesReady(passesReady) {
      this.$store.dispatch(`${this.storeModuleName}/passesReady`, {
        passesReady: passesReady,
      });
    },

    clearQuestions() {
      this.$store.dispatch(`${this.storeModuleName}/clearQuestions`);
    },

    updateTotalAmount(amount, currency) {
      this.$store.dispatch(`${this.storeModuleName}/updateAmount`, {
        value: amount,
        currency: currency,
      });
    },

    clearOrderInformation() {
      this.$store.dispatch(`${this.storeModuleName}/clearOrderInformation`);
    },

    setOrderInformation(orderInfo) {
      this.$store.dispatch(`${this.storeModuleName}/setRedirectedOrderInfo`, {
        orderInfo,
      });
    },

    verifyAnswers(answers, questions) {
      let verifiedAnswers = {};
      if (answers) {
        let answerList = Object.entries(answers);
        let formKeys = Object.keys(questions);
        for (const [key, value] of answerList) {
          for (const formKey of formKeys) {
            if (questions[formKey].questions.some((x) => x.id == key)) {
              verifiedAnswers[key] = value;
            }
          }
        }
      }
      this.answerQuestions(verifiedAnswers);
    },
  },

  computed: {
    orderFullInfo() {
      return this.$store.state[this.storeModuleName];
    },

    orderInformation() {
      return this.$store.state[this.storeModuleName]?.orderInformation || null;
    },

    hasOrderAvailabilityDate() {
      return this.$store.getters[
        `${this.storeModuleName}/hasOrderAvailabilityDate`
      ];
    },

    hasOrderOption() {
      return this.$store.getters[`${this.storeModuleName}/hasOrderOption`];
    },

    selectedOption() {
      if (!this.hasOrderOption) return null;
      const { option } = this.orderInformation;
      return option;
    },

    selectedAvailabilityDate() {
      return (
        this.$store.state[this.storeModuleName]?.orderInformation
          .selectedDate || null
      );
    },

    hasSelectedPasses() {
      return this.$store.getters[`${this.storeModuleName}/hasSelectedPasses`];
    },

    orderRequest() {
      return this.$store.getters[`${this.storeModuleName}/orderRequest`];
    },

    productPasses() {
      let resultPasses = [];
      let passes = this.$store.state[this.storeModuleName]?.passes;
      let selectedPasses = this.$store.getters[
        `${this.storeModuleName}/getSelectedPasses`
      ];

      if (!!passes) {
        for (let key in passes.value) {
          let value = selectedPasses.find(
            (item) => item.id === passes.value[key].id
          )?.value;
          resultPasses.push({
            ...passes.value[key],
            value,
          });
        }
      }

      return resultPasses;
    },

    getOrderDetails() {
      return this.$store.getters[`${this.storeModuleName}/getDetails`];
    },

    getTravelers() {
      if (this.$route.name == 'transportation-payment') {
        return this.$store.getters[
          `transportation/${this.$route.params.id}/getTravelers`
        ];
      }
      return this.$store.getters[`${this.storeModuleName}/getTravelers`];
    },
  },
};
</script>
