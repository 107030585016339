var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-3 px-md-16"},[_c('v-row',{staticClass:"justify-space-between px-md-2 px-1 ma-md-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-0 pt-1",attrs:{"cols":"12"}},[_c('span',{staticClass:"category-name"},[_vm._v(_vm._s(_vm.title))]),_c('v-divider')],1),(_vm.shouldShowSeeAllBtn)?[_c('v-col',{staticClass:"py-1",attrs:{"cols":"auto"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
            name: 'discoveryCategoryResults',
            query: {
              city: _vm.getCityName,
              title: _vm.title,
              'sub-categories': _vm.seeAllParamSubCategories,
              'start-date': _vm.seeAllParamAvailabilityStart,
              'end-date': _vm.seeAllParamAvailabilityEnd,
            },
          }}},[_c('span',{staticClass:"primary--text font-weight-bold",class:[
              _vm.$vuetify.breakpoint.smAndDown ? 'caption' : 'subtitle-2',
            ]},[_vm._v(" "+_vm._s(_vm.$t('common.see_all'))+" ")])])],1)]:_vm._e()],2),_c('v-row',{staticClass:"mx-n3",attrs:{"no-gutters":""}},[_c('CarouselComponent',{attrs:{"items":_vm.items,"displayPage":_vm.displayPage,"title":_vm.title,"catalogType":_vm.catalogType}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }