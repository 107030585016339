<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters class="instructions">
      <v-col cols="12">
        <PDSectionTitle :title="$t('product.menu_names.instructions')" />
      </v-col>

      <template v-if="shouldShowMap">
        <v-col cols="12" class="pb-5" data-cy="product-details-locations-map">
          <LMap
            :zoom="15"
            :options="{
              dragging: false,
              doubleClickZoom: false,
              scrollWheelZoom: false,
            }"
            style="height: 420px; width: 100%"
            :center="locationCenter"
          >
            <LTileLayer :url="letleafMapUrl" subdomains="abc" />
          </LMap>
        </v-col>
      </template>

      <template v-if="departurePoint">
        <v-col cols="12" class="py-4">
          <PDSectionTextBlock
            :hideDivider="true"
            :title="$t('product.departure_point')"
            :text="departurePoint"
            withLink
          >
            <template v-slot:content>
              <a
                class="google-link mt-3 mt-sm-0"
                :href="googleMapsLink"
                target="__blank"
                data-cy="product-details-locations-map-link"
              >
                {{ $t('product.open_google_map') }}
                <v-img
                  :alt="$t('product.external_link_icon')"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  src="@/assets/externalLink.svg"
                />
              </a>
            </template>
          </PDSectionTextBlock>
        </v-col>
      </template>

      <template v-if="startTime">
        <v-col cols="12">
          <PDSectionTextBlock
            :title="$t('start_time')"
            :text="startTime"
            :hideDivider="true"
          />
        </v-col>
      </template>

      <template v-if="returnDetails">
        <v-col cols="12">
          <PDSectionTextBlock
            :hideDivider="true"
            :title="$t('product.return_details')"
            :text="returnDetails"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { LMap, LTileLayer } from 'vue2-leaflet';
import PDSectionTitle from './PDSectionTitle';
import PDSectionTextBlock from './PDSectionTextBlock';

export default {
  name: 'PDInstructions',
  components: { PDSectionTitle, PDSectionTextBlock, LMap, LTileLayer },
  computed: {
    location() {
      if (!this.hasModel) return null;
      let { city, country } = this.model;
      let location = 'Stunning Hill, Jamica';
      if (country) {
        location = `${country}`;
        if (city) location = `${city}, ${country}`;
      } else if (this.model.locations.length > 0) {
        location = this.model.locations[0].address;
      }
      return location;
    },
    shouldShowMap() {
      const [lng, lat] = this.locationCenter;
      return lng !== 0 && lat !== 0;
    },
    locationCenter() {
      if (!this.hasModel) return [0, 0];
      else if (this.model.locations.length > 0) {
        const { latitude, longitude } = this.model.locations[0];
        return [latitude, longitude];
      }
      return [0, 0];
    },
    letleafMapUrl() {
      return 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';
    },
    googleMapsLink() {
      const [latitude, longitude] = this.locationCenter;
      return `https://maps.google.com/?q=${latitude},${longitude}`;
    },
  },
  props: {
    model: Object,
    hasModel: Boolean,
    returnDetails: String,
    departurePoint: String,
    startTime: String,
  },
};
</script>

<style scoped lang="scss">
.google-link {
  @include font-size(14, 150, 600);
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: var(--v-grey8-base);
  border: 2px solid #e4e8ef;
  border-radius: 8px;
  padding: 4px 10px;
  text-decoration: none;

  .v-image {
    margin-left: 6px;
  }
}

.leaflet-container {
  z-index: 1;
}
</style>
