<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters class="cancellationPolicy">
      <v-col cols="12">
        <PDSectionTitle :title="$t('product.menu_names.terms_conditions')" />
      </v-col>
      <v-col cols="12">
        <PDSectionTextBlock
          :title="$t('product.details_sections.cancellation_policy')"
          :text="cancellationPolicy"
          :hideDivider="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PDSectionTitle from './PDSectionTitle';
import PDSectionTextBlock from './PDSectionTextBlock';

export default {
  name: 'PDTermsConditions',
  components: { PDSectionTitle, PDSectionTextBlock },
  props: {
    cancellationPolicy: {
      type: String,
      default: '',
    },
  },
};
</script>
