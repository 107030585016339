<template>
  <v-container class="product-page pa-0" fluid>
    <vue-headful :title="pageTitle" />
    <v-banner
      v-if="error !== null && error.status !== 400"
      single-line
      :sticky="true"
      style="z-index:3;"
      class="error-banner"
    >
      <v-avatar slot="icon" color="error" size="40">
        <v-icon icon="mdi-lock" color="white">mdi-alert</v-icon>
      </v-avatar>
      <div class="title" v-if="error.message">{{ error.message }}</div>
      <template v-slot:actions>
        <v-btn text color="deep-purple accent-4" @click="refresh">
          {{ $t('refresh') }}
        </v-btn>
      </template>
    </v-banner>
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="11" lg="10">
        <router-view class="product-route-port"></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductDetailsMixins from './mixins/ProductDetailsMixins';
import vueHeadful from 'vue-headful';
import ProductNavigationMixins from '../mixins/ProductNavigationMixins';
import * as Sentry from '@sentry/vue';
export default {
  name: 'product-page',
  components: { vueHeadful },
  mixins: [ProductDetailsMixins, ProductNavigationMixins],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const path =
        from.name === 'discoveryWithCity'
          ? {
              name: from.name,
              query: from.query,
            }
          : { name: 'home' };
      vm.setRedirectPathFromOrderConfirmationPage({
        redirectPathFromConfirmation: path,
      });
    });
  },
  created() {
    Sentry.setTag('module', 'booking');
    this.registerStore();
    this.loadProductDetails();
    this.loadSimilarProducts();
  },
  computed: {
    partnerName() {
      return this.$store.state.configs && this.$store.state.configs.title
        ? this.$store.state.configs.title
        : '';
    },
    pageTitle() {
      return this.hasDetails
        ? `${this.productDetails.value.title} | ${this.partnerName} | Guestlogix`
        : '';
    },
  },
  watch: {
    productId(newValue, oldValue) {
      if (!!newValue && !!oldValue) {
        this.registerStore();
        this.loadProductDetails();
        this.loadSimilarProducts();
      }
    },
  },
  methods: {
    refresh() {
      this.loadProductDetails();
    },
  },
};
</script>

<style lang="scss" scoped>
.error-banner {
  background: transparent;
  z-index: 999;
}
.product-page {
  margin-bottom: 40px;
}
.slide-in-enter-active {
  animation: slide-up 1.5s;
}
.slide-in-leave-active {
  animation: slide-up 1.5s reverse;
}
.fade-in-enter-active {
  animation: fade-in 1.5s;
}
.fade-in-leave-active {
  animation: fade-in 0.5s reverse;
}
@keyframes slide-up {
  0% {
    bottom: -100px;
  }
  50% {
    bottom: -30;
  }
  100% {
    bottom: 0;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
    height: 0;
  }
  1% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}
.product-route-port {
  &,
  div {
    z-index: 2;
  }
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
</style>
