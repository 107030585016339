<template>
  <v-container fluid class="pa-0 ma-0 align-center">
    <div class="d-flex flex-wrap justify-md-end">
      <v-row no-gutters v-for="(step, i) in steps" :key="i" class="mx-0 steps">
        <v-col cols="12" class="pa-0 d-flex">
          <v-row
            @click="switchSteps(step)"
            :class="{
              isActiveHover:
                step.status !== 'ACTIVE' &&
                isTravellersDone &&
                currentStep === 2,
            }"
            no-gutters
            class="pa-0 d-flex align-center"
          >
            <ProgressIndicatorCheckout
              @is-done="isTravellersDone = true"
              :step="step"
            />
            <span
              class="pl-1 size14-weight400"
              :class="{ 'font-weight-bold': step.status === 'ACTIVE' }"
            >
              {{ step.name }}
            </span>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ProgressIndicatorCheckout from './ProgressIndicatorCheckout';
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins';
export default {
  name: 'ProgressIndicatorCheckoutWrapper',
  components: { ProgressIndicatorCheckout },
  mixins: [ProductCheckoutMixins],
  props: {
    steps: {
      default: function() {
        return [
          {
            name: this.$t('product.steps.names.traveller_details'),
            status: 'ACTIVE',
          },
          {
            name: this.$t('product.steps.names.review_payment'),
            status: 'PENDING',
          },
        ];
      },
      type: Array,
    },
  },
  data() {
    return {
      isTravellersDone: false,
    };
  },
  methods: {
    switchSteps(step) {
      if (
        step.name === 'Traveller Details' ||
        step.name === 'Contact Details'
      ) {
        this.backToTraveller();
        this.clearOrderState();
        this.$emit('clear-order-created');
      }
    },
    backToTraveller() {
      if (this.isTravellersDone && this.currentStep === 2) {
        this.$router.back();
      }
    },
    goToReview() {
      if (this.isTravellersDone && this.currentStep === 1) {
        this.$router.forward();
      }
    },
  },
  computed: {
    totalSteps() {
      return this.steps.length;
    },
    currentStep() {
      let current = 0;
      for (let step of this.steps) {
        if (step.status === 'DONE' || step.status === 'ACTIVE') {
          current++;
        } else {
          break;
        }
      }
      return current;
    },
  },
};
</script>

<style lang="scss" scoped>
.isActiveHover {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700 !important;
  }
}
.steps {
  position: relative;
  flex: 0 0 auto;
  padding: 0px 10px 0px 20px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    width: 10px;
    height: 1px;
    background: #b8c1d1;
  }

  &:first-child {
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  &:last-child {
    padding-right: 0;
  }
}
</style>
