<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <ProductDetails
          class="details mt-4"
          :model="productDetails.value"
          :isLoading="productDetails.isLoading"
          :error="productDetails.error"
        />
      </v-col>
      <v-col
        cols="12"
        class="px-3 px-sm-16"
        v-for="(catalog, index) in displayedSimilarProducts"
        :key="index"
      >
        <template v-if="isFeaturedWebItemCategory(catalog)">
          <CategoryGroupContainer
            :catalog="catalog"
            displayPage="Landing Page"
            class="py-6"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductDetails from './features/Details/ProductDetails';
import ProductDetailsMixins from './mixins/ProductDetailsMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import CategoryGroupContainer from '@/modules/common/components/CategoryGroupContainer';

export default {
  name: 'ProductDetailsPage',
  mixins: [ProductDetailsMixins, GoogleTagManagerMixins],
  components: { ProductDetails, CategoryGroupContainer },

  created() {
    if (this.hasDetails) {
      this.fireDetailsViewEvent();
    }
  },

  computed: {
    details() {
      return this.detailsModel;
    },
  },

  watch: {
    details(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.fireDetailsViewEvent();
      }
    },
  },

  methods: {
    isFeaturedWebItemCategory(catalog) {
      return catalog.type == 'Item';
    },

    fireDetailsViewEvent() {
      this.pushProductDetailsViewAnalytics(
        this.productId,
        'booking',
        'list',
        'Booking - Product Details'
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
