<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col class="pt-1">
        <v-row
          v-for="(item, index) in items"
          :key="index"
          no-gutters
          justify="space-between"
        >
          <v-col cols="auto" class="flex-nowrap">
            <div class="badge">
              <div class="badge-quantity">{{ item.quantity }}</div>
            </div>
          </v-col>
          <v-col>
            <div class="item-title">
              {{ item.title }}
              <small>
                &nbsp;&#215;&nbsp;{{ item.price | currency(currency) }}
              </small>
            </div>
          </v-col>
          <v-col cols="auto">
            <div class="item-price">
              {{ (item.price * item.quantity) | currency(currency) }}
            </div>
          </v-col>
        </v-row>

        <template v-if="discount">
          <v-divider class="my-2" />
          <v-row no-gutters class="mt-3 justify-space-between">
            <div class="subtitle-1">
              {{ $t('discount_label_text') }} {{ discount.name }}
            </div>
            <div class="subtitle-1">
              {{ discount.discountAmount | currency(currency) }}
            </div>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OrderItems',
  props: {
    currency: {
      type: String,
      default: 'USD',
    },
    items: {
      type: Array,
      default: () => [
        {
          quantity: 2,
          title: 'Adult ($34.99)',
          price: 200.43,
        },
      ],
    },
    discount: {
      type: Object,
      default: null,
    },
  },

  computed: {
    discountExists() {
      return this.discount !== null;
    },

    discountValue() {
      if (!this.discount) return 0;
      return Math.abs(this.discount.discountAmount);
    },

    discountTermsAndCons() {
      if (!this.discount) return '';
      return this.discount.termsAndConditions;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  width: 24px;
  height: 24px;

  .badge-quantity {
    width: 100%;
    text-align: center;
    color: var(--v-grey9-base);
    @include font-size(16, 150, 600);
  }
}
.discount-message {
  color: black;
}

.item-price {
  @include font-size(16, 150, 600);
}

.item-title {
  @include font-size(16, 150, 400);
}
</style>
