<template>
  <v-container class="px-3 px-md-16">
    <v-row no-gutters class="justify-space-between px-md-2 px-1 ma-md-0">
      <v-col cols="12" class="pb-0 pt-1">
        <span class="category-name">{{ title }}</span>
        <v-divider />
      </v-col>

      <template v-if="shouldShowSeeAllBtn">
        <v-col cols="auto" class="py-1">
          <router-link
            class="text-decoration-none"
            :to="{
              name: 'discoveryCategoryResults',
              query: {
                city: getCityName,
                title: title,
                'sub-categories': seeAllParamSubCategories,
                'start-date': seeAllParamAvailabilityStart,
                'end-date': seeAllParamAvailabilityEnd,
              },
            }"
          >
            <span
              class="primary--text font-weight-bold"
              :class="[
                $vuetify.breakpoint.smAndDown ? 'caption' : 'subtitle-2',
              ]"
            >
              {{ $t('common.see_all') }}
            </span>
          </router-link>
        </v-col>
      </template>
    </v-row>
    <v-row no-gutters class="mx-n3">
      <CarouselComponent
        :items="items"
        :displayPage="displayPage"
        :title="title"
        :catalogType="catalogType"
      />
    </v-row>
  </v-container>
</template>

<script>
import CarouselComponent from './CarouselComponent';

export default {
  name: 'category',
  components: { CarouselComponent },
  props: {
    displayPage: String,
    title: String,
    catalogType: {
      type: String,
      default: 'Item',
      validator: (value) =>
        ['Item', 'WebItem', 'QueryItem'].indexOf(value) !== -1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    seeAllQueryParams: {
      type: Object,
      default: () => {},
    },
    showSeeAllBtn: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getCityName() {
      return !!this.seeAllQueryParams ? this.seeAllQueryParams.city : '';
    },

    shouldShowSeeAllBtn() {
      return (
        this.showSeeAllBtn &&
        !!this.seeAllParamSubCategories &&
        !!this.seeAllParamAvailabilityStart &&
        !!this.seeAllParamAvailabilityEnd
      );
    },

    seeAllParamSubCategories() {
      return !!this.seeAllQueryParams &&
        !!this.seeAllQueryParams.subCategories &&
        !!this.seeAllQueryParams.subCategories.length
        ? this.seeAllQueryParams.subCategories
        : null;
    },

    seeAllParamAvailabilityStart() {
      return !!this.seeAllQueryParams
        ? this.seeAllQueryParams.availabilityStart
        : null;
    },

    seeAllParamAvailabilityEnd() {
      return !!this.seeAllQueryParams
        ? this.seeAllQueryParams.availabilityEnd
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.category-name {
  font-size: 20px;
  font-weight: bold;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 24px;
  }
}

.v-divider {
  border: 1px solid #cfd5e0;
}

.text-decoration-none {
  text-decoration: none;
}
</style>
