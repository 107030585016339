<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters class="highlights">
      <v-col cols="12" class="pb-0 pt-0">
        <PDSectionTitle :title="$t('product.menu_names.highlights')" />
      </v-col>

      <v-col cols="12">
        <ul class="pa-0 highlights-list">
          <template v-if="duration">
            <li class="highlights-item pa-0 d-flex align-center">
              <simple-svg
                width="20"
                height="20"
                :src="require(`@/assets/highlights/duration.svg`)"
              />
              <span class="ml-3">
                {{ $t('booking.highlights.duration') }}: {{ duration }}
              </span>
            </li>
          </template>

          <template v-if="model.freeCancellation">
            <li class="highlights-item pa-0 d-flex align-center">
              <simple-svg
                width="20"
                height="20"
                :src="require(`@/assets/highlights/cancellation.svg`)"
              />
              <span class="ml-3">
                {{ $t('booking.highlights.free_cancellation') }}
              </span>
              <span class="required">*</span>
            </li>
          </template>

          <template v-if="hasSafetyMeasures">
            <li class="highlights-item pa-0 d-flex align-center">
              <simple-svg
                width="20"
                height="20"
                :src="require(`@/assets/highlights/measures.svg`)"
              />
              <span class="ml-3">
                {{ $t('product.menu_names.health_safety_measures') }}
              </span>
            </li>
          </template>

          <template v-if="hotelAvailable">
            <li class="highlights-item pa-0 d-flex align-center">
              <simple-svg
                width="20"
                height="20"
                :src="require(`@/assets/highlights/hotel.svg`)"
              />
              <span class="ml-3">{{
                $t('booking.highlights.hotel_available')
              }}</span>
            </li>
          </template>

          <template v-if="printedTicket">
            <li class="highlights-item pa-0 d-flex align-center">
              <simple-svg
                width="20"
                height="20"
                :src="require(`@/assets/highlights/ticket.svg`)"
              />
              <span class="ml-3">
                {{ $t('booking.highlights.printed_mobile_ticket') }}
              </span>
            </li>
          </template>

          <template v-if="language">
            <li class="highlights-item pa-0 d-flex align-center">
              <simple-svg
                width="20"
                height="20"
                :src="require(`@/assets/highlights/language.svg`)"
              />
              <span class="ml-3">
                {{ $t('booking.highlights.more_language') }}
              </span>
            </li>
          </template>

          <template v-if="ticketDelivery">
            <li class="highlights-item pa-0 d-flex align-center">
              <simple-svg
                width="20"
                height="20"
                :src="require(`@/assets/highlights/ticket.svg`)"
              />
              <span class="ml-3">
                {{ $t('booking.highlights.instant_ticket_delivery') }}
              </span>
            </li>
          </template>

          <template v-if="wheelchair">
            <li class="highlights-item pa-0 d-flex align-center">
              <simple-svg
                width="20"
                height="20"
                :src="require(`@/assets/highlights/wheelchair.svg`)"
              />
              <span class="ml-3">
                {{ $t('booking.highlights.wheelchair_accessible') }}
              </span>
            </li>
          </template>
        </ul>
      </v-col>

      <template v-if="data">
        <v-col cols="12" tag="p" class="highlights-text">
          {{ data }}
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import PDSectionTitle from './PDSectionTitle';
import ProductDetailsSafetyMeasuresMixins from '@/modules/product/booking/mixins/ProductDetailsSafetyMeasuresMixins';

export default {
  name: 'PDHighlights',
  components: { PDSectionTitle },
  mixins: [ProductDetailsSafetyMeasuresMixins],

  props: {
    hasModel: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      default: () => {},
    },
    data: {
      type: String,
      default: '',
    },
    duration: {
      type: String,
      default: '',
    },
    hotelAvailable: {
      type: String,
      default: '',
    },
    wheelchair: {
      type: String,
      default: '',
    },
    ticketDelivery: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      default: '',
    },
    printedTicket: {
      type: String,
      default: '',
    },
  },

  filters: {
    textFormatting(val) {
      return val.replace(/_/g, ' ');
    },
  },
};
</script>

<style scoped lang="scss">
.highlights {
  margin-top: 3px;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    margin-top: 0;
  }

  &-list {
    list-style: none;
  }

  &-item {
    color: var(--v-grey8-base);
    width: 50%;
    @include font-size(16, 150, 400);

    &:not(&:last-of-type) {
      margin-bottom: 16px;
    }

    @media (min-width: map-get($grid-breakpoints, sm)) {
      width: 100%;
    }
  }

  &-icon {
    margin-right: 14px;
  }

  &-text {
    padding-bottom: 48px;
    color: var(--v-grey8-base);
    @include font-size(16, 150, 400);

    @media (min-width: map-get($grid-breakpoints, md)) {
      padding-top: 0;
      padding-bottom: 56px;
    }
  }
}

.required {
  color: var(--v-primary-base);
}
</style>
