<script>
export default {
  name: 'LoyaltyMixins',
  created() {
    this.checkLoyaltyFF();
  },
  data() {
    return {
      loyaltyFF: false,
    };
  },
  methods: {
    async checkLoyaltyFF() {
      this.loyaltyFF = await this.$ldclient.variation(
        'loyalty-points',
        false,
        true
      );
      this.$ldclient.$on('change:loyalty-points', (value) => {
        this.loyaltyFF = value;
      });
    },
  },
  computed: {
    enableLoyalty() {
      return this.loyaltyFF;
    },
  },
};
</script>
