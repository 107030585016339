<script>
export default {
  name: 'ProductNavigationMixins',
  methods: {
    setRedirectPathFromOrderConfirmationPage({ redirectPathFromConfirmation }) {
      this.$store.dispatch(`product/setRedirectPathFromOrderConfirmationPage`, {
        redirectPathFromConfirmation,
      });
    },
  },
  computed: {
    redirectPathFromConfirmation() {
      return this.$store.getters[`product/redirectPathFromConfirmation`];
    },
  },
};
</script>
