<template>
  <v-sheet class="sign-in-cta-card">
    <v-container class="pa-0">
      <v-row class="pa-2 px-4">
        <v-col cols="12" class="py-1">
          <div>
            Collect Miles for your future trip!
            <a>Sign in</a>
            or
            <a>Sign up</a> now.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: 'SignInCtaCard',
  methods: {
    //removed the login functionality
  },
};
</script>

<style lang="scss" scoped>
.sign-in-cta-card {
  margin-top: 20px;
  width: 100%;
  background: rgba(48, 108, 197, 0.07);
  border-radius: 4px;
  border: 1px solid #306cc5;

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
