<template>
  <div class="d-md-flex options-card_wrapper">
    <div class="options-card_button d-md-none" @click="show = true">
      <div class="size16-weight700 options-card_text-half d-flex justify-start">
        {{ priceStartAt.value | currency(priceStartAt.baseCurrency) }}
        {{ priceStartAt.baseCurrency }}
      </div>
      <div class="size16-weight700 options-card_text-half d-flex justify-end">
        {{ $t('product.check_availability') }}
      </div>
      <div class="size12-weight400 options-card_text-full d-flex justify-start">
        {{ checkoutStrategy }}
      </div>
    </div>
    <div class="d-none d-md-flex">
      <OptionsBookingCtaCard
        :price="priceStartAt"
        :loading="isLoading"
        :freeCancellation="cancellation"
        @select-option-click="goToAvailability()"
        :enableCta="isAvailable"
      />
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <OptionsBookingCtaCardModal
          v-model="show"
          :price="priceStartAt"
          :loading="isLoading"
          :freeCancellation="cancellation"
          @select-option-click="goToAvailability()"
          :enableCta="isAvailable"
        />
      </template>
    </div>
  </div>
</template>

<script>
import OptionsBookingCtaCard from '@/modules/product/booking/features/OptionsBookingCtaCard.vue';
import OptionsBookingCtaCardModal from '@/modules/product/booking/features/OptionsBookingCtaCardModal.vue';
import ConfigsMixins from '@/core/mixins/ConfigsMixins.vue';
import ProductDetailsMixins from '@/modules/product/booking/mixins/ProductDetailsMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'options-cta-card-container',
  mixins: [ProductDetailsMixins, GoogleTagManagerMixins, ConfigsMixins],
  components: { OptionsBookingCtaCard, OptionsBookingCtaCardModal },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    priceStartAt() {
      if (!this.hasDetails)
        return { value: 0, baseCurrency: this.defaultCurrency };
      else {
        return this.detailsModel.priceStartingAt;
      }
    },

    isTiqets() {
      return this.detailsModel.supplier.name === 'Tiqets';
    },

    hasCancellationPolicy() {
      return (
        this.detailsModel.information.filter(
          (info) =>
            info.label ===
            this.$t('product.details_sections.cancellation_policy')
        ).length > 0
      );
    },

    cancellation() {
      return false;
    },

    isAvailable() {
      return this.hasDetails ? this.detailsModel.isAvailable : true;
    },

    checkoutStrategy() {
      return this.hasDetails ? this.detailsModel.checkoutStrategy : '';
    },
  },

  methods: {
    goToAvailability() {
      this.pushCheckoutAnalytics(this.productId, 1, 'booking');
      this.$router.push({ name: 'booking-availability' });
    },
  },
};
</script>

<style lang="scss" scoped>
.options-card {
  &_wrapper {
    width: 100%;
    background: #fff;
  }

  &_button {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 20px;
    background: var(--v-primary-base);
    color: #fff;
  }

  &_text {
    &-half {
      flex-basis: 50%;
    }

    &-full {
      flex-basis: 100%;
      padding: 5px 0;
    }
  }
}
</style>
