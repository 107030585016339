<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-sheet class="options-cta-card d-flex flex-column">
      <v-container
        class="
          px-5
          py-3
          d-flex
          justify-space-between
          align-center
          options-cta-mobile-header
        "
      >
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="1" class="d-flex justify-start">
            <template v-if="currentStep.id !== 1">
              <v-btn icon small @click="prevStep">
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
            </template>
          </v-col>
          <v-col cols="auto">
            <span class="availability-title">
              {{ $t('product.check_availability') }}
            </span>
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn icon small @click="closeDialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pa-0 options-cta-header">
        <v-row class="pa-5 ma-0">
          <v-col cols="12" class="pa-0 options-cta-header-title">
            {{ price.value | currency(price.baseCurrency) }}
            {{ price.baseCurrency }}
          </v-col>
          <v-col cols="12" class="pa-0 pt-1 per-person">
            {{ $t('booking.from_price_per', { productType }) }}
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-container class="options-progress px-5">
        <v-row no-gutters>
          <v-col cols="12">
            <ProgressIndicatorDetailsWrapper :steps="steps" />
          </v-col>
        </v-row>
      </v-container>

      <template v-if="currentStep.id == 1">
        <v-container class="pa-0 pb-5">
          <v-row no-gutters>
            <v-col cols="12">
              <OptionsDatePicker
                :loading="productAvailability.isLoading"
                :selected="selectedDate"
                @availabilityDateSelected="availabilityDateSelected"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-if="currentStep.id == 2">
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col cols="12">
              <OptionsList
                :current="currentSelectedOptionId"
                :selected="selected"
                :list="availabilityOptionsByDate"
                @selectItem="selectItem"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-if="currentStep.id == 3">
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col cols="12">
              <template v-if="passError && getDetails.minUnits !== null">
                <div class="error-message px-5">
                  {{ $t('booking.total_passengers_error', { maximumPasses }) }}
                </div>
              </template>
              <PassTypesWidget
                :passes="convertedPasses"
                :isLoading="productPasses.isLoading"
                :error="productPasses.error"
                @passChange="passesSelected($event)"
                typePass="pass"
                :passError="passError"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <v-divider />
      <v-container class="px-5 py-6">
        <v-row no-gutters>
          <v-col cols="12">
            <OptionsSteps
              :currentStep="currentStep"
              :selectedDate="selectedDate"
              :orderItems="orderItems"
              :selectedOption="selectedOption"
              @goToOptionsStep="goTo(1)"
              @goToDateStep="goTo(0)"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-spacer />
      <v-container class="pa-0 sticky">
        <v-row no-gutters>
          <v-col>
            <v-btn
              tile
              :disabled="disabledButton"
              color="primary"
              @click="buttonAction"
              block
              data-cy="select-booking-option"
              x-large
            >
              <span class="size20-weight700">
                <template v-if="currentStep.id == 3">
                  {{
                    (buttonText ? buttonText : 0) | currency(amount.currency)
                  }}
                  - {{ $t('booking.book_this_tour') }}
                </template>
                <template v-else>
                  {{ buttonText }}
                </template>
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>
<script>
import OptionsBookingCtaCardMixin from '@/modules/common/mixins/OptionsBookingCtaCardMixin';
import ProgressIndicatorDetailsWrapper from '@/modules/product/features/ProgressIndicator/ProgressIndicatorDetailsWrapper.vue';
import PassTypesWidget from '@/modules/product/features/PassTypesWidget';
import OptionsDatePicker from '../../../common/components/OptionsComponents/OptionsDatePicker.vue';
import OptionsList from '../../../common/components/OptionsComponents/OptionsList.vue';
import OptionsSteps from '../../../common/components/OptionsComponents/OptionsSteps.vue';

export default {
  name: 'OptionsBookingCtaCardModal',
  mixins: [OptionsBookingCtaCardMixin],
  components: {
    ProgressIndicatorDetailsWrapper,
    PassTypesWidget,
    OptionsDatePicker,
    OptionsList,
    OptionsSteps,
  },
  props: {
    value: Boolean,
    loading: {
      default: true,
      type: Boolean,
    },
    price: {
      default: () => ({ value: 200, baseCurrency: 'USD' }),
      type: Object,
    },
    freeCancellation: {
      default: true,
      type: Boolean,
    },
    instantConfirmation: {
      default: false,
      type: Boolean,
    },
    productType: {
      default: 'person',
      type: String,
    },
    enableCta: {
      default: true,
      type: Boolean,
    },
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    selectOptionClick() {
      this.$emit('select-option-click');
    },

    closeDialog() {
      this.show = false;
    },

    prevStep() {
      if (this.currentStep.id == 2) {
        this.goTo(0);
      } else if (this.currentStep.id == 3) {
        this.goTo(1);
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.options {
  &-progress {
    padding-top: 19px;
    padding-bottom: 7px;
  }

  &-cta-header {
    background: var(--v-grey1-base);

    &-title {
      @include font-size(24, 150, 700);
    }
  }

  &-cta-mobile-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
    border-bottom: 1px solid var(--v-grey3-base);
  }

  &-cta-card {
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0;
    background: #fff;
    overflow: auto;
    border-color: #e8e6e1;
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
    .v-divider {
      border-width: 1px;
    }
    .from {
      color: #857f72;
    }
    .per-person {
      color: var(--v-grey7-base);
      @include font-size(14, 150, 700);
    }
    .secondary {
      fill: var(--v-secondary-base);
    }
    .primary {
      fill: var(--v-primary-base);
    }
    .cut-out {
      position: relative;
      left: -7px;
      top: -7px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: white;
      &.right {
        border-right: 2px solid #e8e6e1;
        border-top: 2px solid #e8e6e1;
        transform: rotate(45deg);
      }
      &.left {
        border-left: 2px solid #e8e6e1;
        border-bottom: 2px solid #e8e6e1;
        right: -7px;
        transform: rotate(45deg);
        left: unset;
      }
    }
  }

  &-passes {
    &-total {
      padding-top: 10px;
      color: var(--v-grey8-base);
    }
  }
}
.sticky {
  position: sticky;
  bottom: 0;
  background-color: #f5f5f5 !important;
}

.availability-title {
  @include font-size(16, 150, 700);
}
</style>
