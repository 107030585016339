<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="!hideDivider">
          <v-divider class="mb-5" />
        </template>

        <h3 class="block-title mb-3">{{ title }}</h3>
        <div
          :class="{
            'd-flex justify-sm-space-between align-start align-sm-center flex-column flex-sm-row': withLink,
          }"
        >
          <vue-markdown
            :source="text"
            class="block-text mb-0"
            :class="{ withLink }"
          />
          <slot name="content" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PDSectionTextBlock',
  props: {
    hideDivider: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Block title',
    },
    text: {
      type: String,
      default: 'Block text',
    },
    withLink: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.block {
  &-title,
  &-text {
    color: var(--v-grey8-base);
  }

  &-title {
    @include font-size(16, 130, 500);
  }

  &-text {
    @include font-size(16, 150);

    ::v-deep p {
      margin-bottom: 0;
    }

    &.withLink {
      max-width: 500px;
    }
  }
}

.v-divider {
  flex: 1px;
}
</style>
