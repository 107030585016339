<script>
import moment from 'moment';
import ProductAvailabilityMixins from '@/modules/product/booking/mixins/ProductAvailabilityMixins';
import ProductOrderInformationMixins from '@/modules/product/booking/mixins/ProductOrderInformationMixins';
import ProductPassesMixins from '@/modules/product/booking/mixins/ProductPassesMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import { getItemInfo } from '@/core/mixins/tagManagerUtils';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'OptionsBookingCtaCardMixin',
  mixins: [
    ProductAvailabilityMixins,
    ProductOrderInformationMixins,
    ProductPassesMixins,
    FlightsMixins,
    GoogleTagManagerMixins,
    HotJarMixins,
  ],

  data() {
    return {
      passError: false,
      renderComponent: true,
      selectedDate: null,
      daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      steps: [
        { id: 1, name: 'Date', status: 'ACTIVE' },
        { id: 2, name: 'Options', status: 'PENDING' },
        { id: 3, name: 'Travelers', status: 'PENDING' },
      ],
      currentStep: { id: 1, name: 'Date', status: 'ACTIVE' },
      availabilityOptionsByDate: null,
      currentSelectedOptionId: this.selectedOptionId,
      availableOptions: this.availabilityOptionsByDate,
      selected: false,
    };
  },

  watch: {
    availabilityOptionsByDate(val) {
      if (!this.selectedOptionId && val.length === 1) {
        this.selectOption({ option: val[0] });
      }
    },

    selectedAvailabilityDate(val) {
      if (!val || val === this.selectedAvailabilityDate) return;
      this.updateAvailabilityOptionsByDate(val);
    },

    selectedOptionId(val) {
      this.currentSelectedOptionId = val;
    },

    total(val) {
      let currency =
        this.orderItems.length > 0 ? this.orderItems[0].currency : null;
      this.updateTotalAmount(val, currency);
    },

    selectedOption(newVal, old) {
      if (!newVal) return;
      if (old && old.id === newVal.id) {
        return;
      } else {
        this.loadPasses({
          optionId: this.orderInformation.option.id,
        });
      }
    },
  },

  computed: {
    disabledButton() {
      return (
        this.loading ||
        !this.selectedDate ||
        this.orderItems.length < 0 ||
        (this.currentStep.id == 2 && !this.currentSelectedOptionId) ||
        (this.currentStep.id == 3 && !this.amount.value)
      );
    },

    buttonText() {
      switch (this.currentStep.id) {
        case 1:
          return this.$t('booking.check_availability');
        case 2:
          return this.$t('booking.add_travellers');
        case 3:
          return this.amount.value;
        default:
          return this.$t('booking.select_option');
      }
    },

    selectedOption() {
      return this.orderInformation.option;
    },

    selectedOptionId() {
      return this.hasOrderOption ? this.orderInformation.option.id : null;
    },

    convertedPasses() {
      const productPasses = this.productPasses.value || [];
      var finalPassList = [];
      let loadedPasses = false;

      for (const pIndex in productPasses) {
        const pass = productPasses[pIndex];
        const value = this.getExistingValue(pass.id);
        if (value > 0) loadedPasses = true;
        finalPassList.push({
          id: pass.id,
          name: pass.title,
          price: pass.price,
          value: value,
          max: pass.maximumQuantity,
          min: pass.minimumQuantity,
          description: pass.description,
        });
      }

      return finalPassList;
    },

    maximumPasses() {
      return this.productPasses.value[0].maximumQuantity;
    },

    totalSelectedPasses() {
      return this.orderInformation.selectedPasses.reduce(
        (accumulator, item) => accumulator + item.value,
        0
      );
    },

    selectedPasses() {
      return this.orderInformation.selectedPasses || [];
    },

    orderItems() {
      const orderItems = [];
      this.selectedPasses.forEach((pass) => {
        for (let index in this.productPasses.value) {
          const passDetails = this.productPasses.value[index];
          const { earningValue } = passDetails;
          let earningValuePoints = 0;
          let earningValueCurrency = '';
          if (earningValue) {
            earningValuePoints = earningValue.value;
            earningValueCurrency = earningValue.baseCurrency;
          }

          if (passDetails.id === pass.id) {
            orderItems.push({
              title: passDetails.title,
              quantity: pass.value,
              price: passDetails.price.value,
              currency: passDetails.price.baseCurrency,
              earningValue: earningValuePoints,
              earningValueCurrency: earningValueCurrency,
            });
            break;
          }
        }
      });
      return orderItems;
    },

    amount() {
      if (this.orderItems.length > 0) {
        let price = {
          value: this.orderItems.map((item) => item.price * item.quantity),
          currency: this.orderItems[0].currency,
        };
        if (price.value.length > 0) {
          price.value = price.value.reduce((a, b) => a + b).toFixed(2);
        }
        return price;
      } else {
        return 0;
      }
    },

    total() {
      if (!this.discount) {
        return this.orderItems.reduce(
          (prev, current) => prev + current.price * current.quantity,
          0
        );
      }
      return this.discount.finalTotal;
    },

    getMinTravelers() {
      return this.getDetails ? this.getDetails.minUnits : null;
    },
  },

  created() {
    if (!this.hasOrderAvailabilityDate) {
      this.loadAvailability({
        startDate: moment().format('YYYY-MM-DD'),
      });
    }
    this.updateAvailabilityOptionsByDate(this.selectedAvailabilityDate);
    if (this.selectedOptionId) {
      this.currentSelectedOptionId = this.selectedOptionId;
    }
    if (this.hasOrderOption) {
      this.loadPasses({
        optionId: this.orderInformation.option.id,
      });
    }
  },

  methods: {
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    goTo(step) {
      this.updateAvailabilityOptionsByDate(this.selectedDate);
      this.currentStep = this.steps[step];
      this.steps[0].status = step === 0 ? 'ACTIVE' : 'DONE';
      this.steps[1].status = step >= 1 ? 'DONE' : 'PENDING';
      this.steps[2].status = step === 2 ? 'DONE' : 'PENDING';
    },

    availabilityDateSelected(val) {
      this.selectedDate = val;
      this.selectAvailabilityDate({ availabilityDate: val });
      this.goTo(0);
    },

    optionSelected(option) {
      this.selectOption({ option });
    },

    updateAvailabilityOptionsByDate(selectedAvailabilityDate) {
      if (!selectedAvailabilityDate) return;
      const selectedMonth = moment(selectedAvailabilityDate)
        .format('YYYY-MM')
        .toString();
      if (
        this.productAvailability.value &&
        this.productAvailability.value[selectedMonth]
      ) {
        let options = this.productAvailability.value[selectedMonth].find(
          (o) => o.date === selectedAvailabilityDate
        );
        if (options.optionSet) {
          this.availabilityOptionsByDate = options.optionSet.options;
        } else {
          this.availabilityOptionsByDate = [
            {
              disclaimer: null,
              id: options.id,
              optionLabel: this.getDetails.title,
            },
          ];
        }
      }
    },

    selectItem(option) {
      this.selected = true;
      this.currentSelectedOptionId = option.id;
      this.optionSelected(option);
    },

    passesSelected(pass) {
      this.selectPass(pass);
    },

    getExistingValue(id) {
      const order = this.orderInformation.selectedPasses.find(
        (p) => p.id == id
      );

      return order ? order.value : 0;
    },

    validateAllPassTypes() {
      if (
        this.getDetails.minUnits !== null &&
        !isNaN(this.getDetails.minUnits) &&
        this.totalSelectedPasses > this.maximumPasses
      ) {
        return false;
      } else {
        return [
          'Adult',
          'Child',
          'Senior',
          'Infant',
          'Youth',
        ].every((passType) => this.validatePassMinimumAmount(passType));
      }
    },

    goToTraveler() {
      if (this.validateAllPassTypes()) {
        this.passesReady(true);
        this.$router.push({
          name: 'booking-form',
          query: this.$route.query,
        });
      } else {
        this.passError = true;
      }
    },

    validatePassMinimumAmount(passesType) {
      const passes = this.convertedPasses.filter(
        (item) => item.name === passesType
      );
      if (passes[0]?.min > passes[0]?.value) {
        return false;
      } else {
        return true;
      }
    },

    buttonAction() {
      switch (this.currentStep.id) {
        case 1:
          this.goTo(1);
          this.pushCheckAvailabilityAnalytics([
            {
              ...getItemInfo(this.productId, 'booking'),
              selected_date: this.selectedDate,
            },
          ]);
          this.pushHotJarAnalytics('check_availability');
          break;
        case 2:
          this.goTo(2);
          break;
        case 3:
          this.goToTraveler();
          let selectedOption = this.availabilityOptionsByDate.find(
            (item) => item.id === this.currentSelectedOptionId
          );
          let orderAnalytics = {
            productId: this.productId,
            date: this.selectedDate,
            option: selectedOption,
            price: this.amount.value,
            passes: this.convertedPasses,
            type: 'booking',
          };
          this.pushProductBookThisTourAnalytics(orderAnalytics);
          this.pushHotJarAnalytics('book_this_tour');
          break;
        default:
          this.selectOptionClick();
      }
    },
  },
};
</script>
