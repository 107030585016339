<script>
import ProductBookingMixins from './ProductBookingMixins.vue';

export default {
  name: 'ProductPassesMixins',
  mixins: [ProductBookingMixins],
  methods: {
    loadPasses({ optionId }) {
      this.$store.dispatch(`${this.storeModuleName}/loadPasses`, {
        productId: this.productId,
        optionId,
      });
    },
    clearPasses() {
      this.$store.dispatch(`${this.storeModuleName}/clearProductPasses`);
    },
  },
  computed: {
    productPasses() {
      return this.$store.state[this.storeModuleName].passes;
    },
    hasPasses() {
      return this.$store.getters[`${this.storeModuleName}/hasPasses`];
    },
    getDetails() {
      return this.$store.getters[`${this.storeModuleName}/getDetails`];
    },
  },
};
</script>
