<template>
  <v-container
    class="pa-5 ma-0 summary-wrapper align-center"
    :class="{ loading }"
  >
    <v-row dense class="pt-1">
      <template v-if="imgUrls && imgUrls.length">
        <v-col cols="auto">
          <v-img
            :alt="title"
            :aspect-ratio="1"
            width="72"
            class="location-image mr-4"
            :src="imgUrls[0]"
          />
        </v-col>
      </template>
      <v-col>
        <h5 class="summary-product">{{ title }}</h5>
      </v-col>
    </v-row>
    <v-divider class="my-4" />

    <OrderSelections
      :selectedDate="selectedDate"
      :ticket="ticket"
      :person="person"
    />

    <v-divider class="my-4" />

    <OrderItems :items="orderItems" :discount="discount" :currency="currency" />

    <v-divider v-if="hasItems" class="my-4" />
    <v-row no-gutters justify="space-between">
      <v-col>
        <h5 class="summary-total-text">{{ $t('total_price') }}:</h5>
      </v-col>
      <v-col cols="auto">
        <span class="summary-total-price">
          {{ total | currency(currency) }}
          {{ currency }}
        </span>
      </v-col>
    </v-row>

    <template v-if="displayLoyalty">
      <v-row no-gutters justify="end">
        <v-col cols="auto">
          <div class="summary-loyalty">
            {{
              $t('booking.order_summary.expansion', {
                totalEarningPoints,
                pointsCurrency,
              })
            }}
          </div>
        </v-col>
      </v-row>
    </template>

    <template v-if="hasNotes">
      <v-row no-gutters class="flex-nowrap py-2">
        <v-col>
          <ul class="notes">
            <li v-for="(note, index) in notes" :key="index" v-html="note" />
          </ul>
        </v-col>
      </v-row>
      <v-divider class="my-2" />
    </template>
  </v-container>
</template>

<script>
import OrderSummaryMixin from './OrderSummaryMixin';

export default {
  name: 'OrderSummary',
  mixins: [OrderSummaryMixin],
};
</script>

<style lang="scss" scoped>
.summary {
  &-wrapper {
    background: #f9f9f9;
    border-radius: 8px;
  }

  &-product,
  &-total-text {
    @include font-size(16, 150, 400);
  }

  &-loyalty {
    @include font-size(16, 150, 700);
  }

  &-total-price {
    @include font-size(20, 150, 700);
  }
}

.loading {
  filter: blur(3px);
}

.notes {
  font-size: 14px;
}

.location-image {
  border-radius: 4px;
  border: 1px solid var(--v-grey3-base);
}
</style>
