<script>
import ProductBookingMixins from './ProductBookingMixins.vue';

export default {
  name: 'ProductAvailabilityMixins',
  mixins: [ProductBookingMixins],
  methods: {
    loadAvailability({ startDate }) {
      this.$store.dispatch(`${this.storeModuleName}/loadAvailability`, {
        productId: this.productId,
        startDate,
      });
    },
    clearAvailability() {
      this.$store.dispatch(`${this.storeModuleName}/clearProductAvailability`);
    },
  },
  computed: {
    productAvailability() {
      return this.$store.state[this.storeModuleName].availability;
    },
    hasAvailability() {
      return this.$store.getters[`${this.storeModuleName}/hasAvailability`];
    },
    selectedAvailabilityDateValue() {
      return this.$store.getters[
        `${this.storeModuleName}/selectedAvailabilityDate`
      ];
    },
  },
};
</script>
