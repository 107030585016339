<template>
  <v-row class="product-details-section">
    <v-col cols="12">
      <template v-if="hasModel">
        <h1 class="product-details-title" data-cy="product-details-title">
          {{ title }}
        </h1>
      </template>

      <template v-else>
        <v-skeleton-loader class="pl-3" max-width="550" type="text" />
      </template>
    </v-col>

    <template v-if="rating">
      <v-col
        cols="auto"
        class="d-flex justify-start rating pa-0 ml-3 mb-0 mb-md-5"
      >
        <PDRating :ratings="rating" />
        <span class="size14-weight700">{{ rating }}</span>
        <span class="pl-1 size14-weight400">{{ totalRatings }}</span>
      </v-col>
    </template>

    <v-col
      cols="12"
      class="page-navigation-sticky pt-5 pt-sm-0"
      :class="{ 'push-top': hasContextFlights || $vuetify.breakpoint.lgAndUp }"
    >
      <CategoryMenuBar @click="scrollToMenu" :menuItems="menuNames" />
    </v-col>

    <v-col cols="12" class="wrapper">
      <v-row justify="center" class="flex-nowrap ma-0">
        <v-col cols="12" md="8" v-if="hasModel" class="wrapper-content py-0">
          <v-container fluid class="px-1 px-sm-0 py-0">
            <template v-if="isHasHighlights">
              <PDHighlights
                class="pt-sm-10 pb-sm-8 pt-8 pb-6"
                v-intersect="{
                  options: {
                    threshold: intersectThreshold,
                    rootMargin: intersectRootMargin,
                  },
                }"
                :hasModel="hasModel"
                :model="model"
                :data="highlights"
                :duration="duration"
                ref="highlights-id"
              />
              <v-divider />
            </template>

            <template v-if="description">
              <PDOverview
                class="py-sm-10 py-6"
                v-intersect="{
                  options: {
                    threshold: intersectThreshold,
                    rootMargin: intersectRootMargin,
                  },
                }"
                :data="description"
                ref="overview-id"
              />
              <v-divider />
            </template>

            <template v-if="hasModel && this.isHasSafetyMeasures">
              <PDHealthSafety
                class="py-sm-10 py-6"
                v-intersect="{
                  options: {
                    threshold: intersectThreshold,
                    rootMargin: intersectRootMargin,
                  },
                }"
                :hasModel="hasModel"
                :model="model"
                ref="safetyMeasures-id"
              />
              <v-divider />
            </template>

            <template v-if="isHasTicketsInfo">
              <PDTickets
                class="pt-sm-10 py-6 pb-0"
                v-intersect="{
                  options: {
                    threshold: intersectThreshold,
                    rootMargin: intersectRootMargin,
                  },
                }"
                ref="tickets-id"
                :includes="includes"
                :excludes="excludes"
                :voucher="voucher"
                :operates="operates"
                :language="language"
                :liveGuide="liveGuide"
                :audioGuide="audioGuide"
              />
              <v-divider />
            </template>

            <template v-if="isHasInstructions">
              <PDInstructions
                class="py-sm-10 py-6"
                v-intersect="{
                  options: {
                    threshold: intersectThreshold,
                    rootMargin: intersectRootMargin,
                  },
                }"
                ref="instructions-id"
                :model="model"
                :hasModel="hasModel"
                :returnDetails="returnDetails"
                :departurePoint="departurePoint"
                :startTime="startTime"
              />
              <v-divider />
            </template>

            <template v-if="isHasMoreInformation">
              <PDMoreInformation
                class="py-sm-10 py-6"
                v-intersect="{
                  options: {
                    threshold: intersectThreshold,
                    rootMargin: intersectRootMargin,
                  },
                }"
                :moreInformation="moreInformation"
                ref="moreInformation-id"
              />
              <v-divider />
            </template>

            <template v-if="cancellationPolicy">
              <PDTermsConditions
                class="pt-sm-10 pt-6 pb-0"
                v-intersect="{
                  options: {
                    threshold: intersectThreshold,
                    rootMargin: intersectRootMargin,
                  },
                }"
                :cancellationPolicy="cancellationPolicy"
                ref="cancellationPolicy-id"
              />
            </template>

            <template v-if="getTranslation || getTiqetsAttribution">
              <v-row no-gutters>
                <template v-if="getTranslation">
                  <v-col cols="12">
                    <a
                      :href="model.providerTranslationAttribution.link"
                      class="translation-attribution-link"
                    >
                      <v-img
                        alt="providerLogo"
                        :contain="true"
                        max-width="161px"
                        height="24px"
                        :src="model.providerTranslationAttribution.image"
                      />
                    </a>
                  </v-col>
                </template>

                <template v-if="getTiqetsAttribution">
                  <v-col cols="12" class="d-flex flex-row">
                    <span class="mr-2 size14-weight700">
                      {{ model.supplier.trademark.copyRight }}
                    </span>
                    <v-img
                      alt="trademarkLogo"
                      :contain="true"
                      max-width="161px"
                      height="24px"
                      :src="model.supplier.trademark.iconUrl"
                      position="left"
                    />
                  </v-col>
                </template>
              </v-row>
            </template>
          </v-container>
        </v-col>
        <v-col class="d-none d-md-flex" cols="4">
          <v-row no-gutters justify="center">
            <v-col cols="auto">
              <div class="wrapper-cta">
                <OptionsCtaCardContainer />
                <div class="d-md-flex wrapper-cta-auth">
                  <SignInCtaCardContainer />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PDRating from '@/modules/product/booking/features/Details/ProductDetailsSections/PDRating';
import PDHighlights from './ProductDetailsSections/PDHighlights';
import PDOverview from './ProductDetailsSections/PDOverview';
import PDHealthSafety from './ProductDetailsSections/PDHealthSafety';
import PDTickets from './ProductDetailsSections/PDTickets';
import PDInstructions from './ProductDetailsSections/PDInstructions';
import PDTermsConditions from './ProductDetailsSections/PDTermsConditions';
import PDMoreInformation from './ProductDetailsSections/PDMoreInformation';
import OptionsCtaCardContainer from './OptionsCtaCardContainer';
import SignInCtaCardContainer from '@/modules/product/booking/features/Details/SignInCtaCardContainer';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import CategoryMenuBar from '@/core/components/CategoryMenuBar';

export default {
  name: 'product-details-section',
  mixins: [FlightsMixins],
  components: {
    PDRating,
    PDHighlights,
    PDOverview,
    PDHealthSafety,
    PDTickets,
    PDInstructions,
    PDTermsConditions,
    PDMoreInformation,
    SignInCtaCardContainer,
    OptionsCtaCardContainer,
    CategoryMenuBar,
  },

  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      intersectThreshold: [0, 0.2],
      intersectRootMargin: '-235px 0px 0px 0px',
      highlightsActive: false,
      overviewActive: false,
      healthActive: false,
      ticketsActive: false,
      instructionsActive: false,
      moreinformationActive: false,
      termsActive: false,
    };
  },

  computed: {
    menuNames() {
      let array = [
        {
          id: 'highlights-id',
          title: this.$t('product.menu_names.highlights'),
          isShow: this.isHasHighlights,
          isActive: this.highlightsActive,
        },
        {
          id: 'overview-id',
          title: this.$t('product.menu_names.overview'),
          isShow: this.description,
          isActive: this.overviewActive,
        },
        {
          id: 'safetyMeasures-id',
          title: this.$t('product.menu_names.health_safety_measures'),
          isShow: this.isHasSafetyMeasures,
          isActive: this.healthActive,
        },
        {
          id: 'tickets-id',
          title: this.$t('product.menu_names.tickets'),
          isShow: this.isHasTicketsInfo,
          isActive: this.ticketsActive,
        },
        {
          id: 'instructions-id',
          title: this.$t('product.menu_names.instructions'),
          isShow: this.isHasInstructions,
          isActive: this.instructionsActive,
        },
        {
          id: 'moreInformation-id',
          title: this.$t('product.menu_names.more_information'),
          isShow: this.isHasMoreInformation,
          isActive: this.moreinformationActive,
        },
        {
          id: 'cancellationPolicy-id',
          title: this.$t('product.menu_names.terms_conditions'),
          isShow: this.cancellationPolicy,
          isActive: this.termsActive,
        },
      ];
      return array.filter((el) => !!el.isShow);
    },

    isHasSafetyMeasures() {
      return this.model && this.model.safetyMeasures?.length > 0;
    },

    isHasHighlights() {
      return (
        this.duration != null ||
        this.highlights != null ||
        (this.model &&
          this.model.safetyMeasures &&
          this.model.safetyMeasures?.length > 0)
      );
    },

    isHasTicketsInfo() {
      return (
        this.includes != null ||
        this.excludes != null ||
        this.voucher != null ||
        this.operates != null ||
        this.language != null ||
        this.liveGuide != null ||
        this.audioGuide != null
      );
    },

    isHasMoreInformation() {
      return this.moreInformation?.length > 0;
    },

    isHasInstructions() {
      return (
        this.returnDetails != null ||
        this.departurePoint != null ||
        this.startTime != null
      );
    },

    getTiqetsAttribution() {
      if (
        this.hasModel &&
        this.model.supplier &&
        this.model.supplier.trademark &&
        this.model.supplier.name === 'Tiqets'
      ) {
        return true;
      } else {
        return false;
      }
    },

    getTranslation() {
      if (
        this.hasModel &&
        this.model.providerTranslationAttribution.image != null &&
        this.model.providerTranslationAttribution.link != null
      ) {
        return true;
      } else {
        return false;
      }
    },

    hasModel() {
      return this.model !== null && this.model !== undefined;
    },

    title() {
      return this.hasModel ? this.model.title : 'Lorem ipsum dolor sit amet';
    },

    duration() {
      return this.getInformationValue('duration');
    },

    rating() {
      return this.hasModel
        ? Math.round(this.model.rating?.averageRating * 100) / 100
        : 0;
    },

    totalRatings() {
      return this.$t('booking.ratings', {
        numbers: this.hasModel ? this.model.rating?.totalNumRatings : 0,
      });
    },

    highlights() {
      return this.getInformationValue('highlights');
    },

    description() {
      if (!this.hasModel) return null;
      const { description } = this.model;
      return description;
    },

    returnDetails() {
      return this.getInformationValue('return_details');
    },

    departurePoint() {
      return this.getInformationValue('departure_point');
    },

    includes() {
      return this.updateIncludedExcludedText('includes');
    },

    excludes() {
      return this.updateIncludedExcludedText('excludes');
    },

    operates() {
      return this.getInformationValue('operating_days');
    },

    language() {
      return this.getInformationValue('language');
    },

    liveGuide() {
      return this.getInformationValue('liveGuide');
    },

    audioGuide() {
      return this.getInformationValue('audioGuide');
    },

    voucher() {
      return this.getInformationValue('voucher_requirements');
    },

    startTime() {
      return this.getInformationValue('start_time');
    },

    cancellationPolicy() {
      return this.getInformationValue('cancellation_policy');
    },

    moreInformation() {
      if (!this.hasModel) return null;
      // list from information block in request
      // show everything else
      const everything = [
        'duration',
        'highlights',
        'includes',
        'excludes',
        'voucher_requirements',
        'usage',
        'start_time',
        'additional_fees',
        'cancellation_policy',
        'departure_point',
        'return_details',
        'operating_days',
      ];
      const { information } = this.model;
      return information.filter((info) => {
        return everything.findIndex((i) => i === info.label) < 1;
      });
    },
  },

  methods: {
    scrollToMenu(refName) {
      let element = this.$refs[refName].$el;
      if (!!element) {
        const y =
          element.getBoundingClientRect().top +
          window.pageYOffset -
          (this.$vuetify.breakpoint.mdAndDown ? 150 : 230);
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },

    getInformationValue(label, { returnLabel } = { returnLabel: false }) {
      if (!this.hasModel) return null;
      const { information } = this.model;
      if (information) {
        const info = information.find((info) => info.label === label);
        if (info) {
          return returnLabel ? info : info.value;
        }
      }
      return null;
    },

    updateIncludedExcludedText(val) {
      const string = this.getInformationValue(val);
      const substring = '*';

      if (string) {
        if (string.includes(substring)) {
          return string;
        } else {
          return string.replace(',', '*');
        }
      } else {
        return null;
      }
    },

    getBlockName(name) {
      switch (name) {
        case 'highlights':
          return `${name}-id`;
        case 'overview':
          return `${name}-id`;
        case 'safetyMeasures':
          return `${name}-id`;
        case 'tickets':
          return `${name}-id`;
        case 'instructions':
          return `${name}-id`;
        case 'moreInformation':
          return `${name}-id`;
        case 'cancellationPolicy':
          return `${name}-id`;
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-navigation-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  background: white;
  z-index: 4;
  padding-bottom: 0;

  &.push-top {
    top: 76px;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      top: 164px;
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      top: 170px;
    }
  }
}

.wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    flex-direction: row;
    justify-content: space-between;
  }

  &-cta {
    @media (min-width: map-get($grid-breakpoints, sm)) {
      position: -webkit-sticky;
      position: sticky;
      top: 194px;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
      padding-top: 0;
    }

    &-auth {
      max-width: 360px;
      margin-left: auto;
    }
  }
}

.product-details-title {
  @include font-size(24, 150, 300);
  margin: 12px auto 0;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    margin: 12px auto 4px;
    @include font-size(36, 150, 300);
  }
}

::v-deep .v-expansion-panel {
  box-shadow: none;

  &:before {
    box-shadow: none !important;
  }
}
::v-deep .includes {
  li {
    list-style-image: url('/svg/check.svg');
  }
  p:before {
    background: url('/svg/check.svg');
  }
}
::v-deep .excludes {
  li {
    list-style-image: url('/svg/close.svg');
  }
  p:before {
    background: url('/svg/close.svg');
  }
}
::v-deep .includes,
::v-deep .excludes {
  li:not(:last-child) {
    margin-bottom: 16px;
  }

  p {
    padding-left: 32px;
    position: relative;
    line-height: 24px;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 7px;
      top: 10px;
      width: 12px;
      height: 10px;
      background-repeat: no-repeat;
      margin: auto;
    }
  }
}
::v-deep .overview,
::v-deep .moreInformation {
  * {
    color: #05090f;
    line-height: 150%;
  }

  ul {
    list-style: none;
    margin-bottom: 28px;

    li {
      font-weight: normal;
      font-size: 14px;

      &:before {
        content: '\2022';
        color: var(--v-grey6-base);
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  b,
  p,
  div {
    display: block;
    width: 100%;
  }

  b {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    margin-bottom: 28px;
    font-weight: normal;

    strong {
      font-weight: bold;
      color: var(--v-primary-base);
      margin-bottom: 16px;
    }
  }

  div >>> div {
    color: var(--v-grey9-base);
    margin-bottom: 8px;
    @include font-size(16, 130, 600);
  }
}

.translation-attribution-link {
  width: 161px;
  height: 24px;
  display: block;
}

.rating span {
  color: var(--v-grey7-base);
}
.section-border:after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -15px;
  left: 0;
  border-radius: 2px;
  background-color: #717171;
}
</style>
