<template>
  <CheckoutLayout>
    <template v-slot:breadcrumb>
      <v-row no-gutters>
        <router-link
          class="size14-weight400 d-flex align-center back-link"
          :class="{ 'text-truncate': $vuetify.breakpoint.mdAndDown }"
          :to="backRouteName"
        >
          <svg
            class="mr-3"
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.49935 12.8337L1.66602 7.00033L7.49935 1.16699"
              :stroke="$vuetify.theme.themes.light.primary.base"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>
            {{ backLinkText }}
          </span>
        </router-link>
      </v-row>
    </template>
    <template v-if="$route.name != 'booking-payment-status'">
      <div class="booking-header">
        <router-link
          :to="'booking-form'"
          class="
            size14-weight700
            d-flex
            align-center
            booking-return-link
            mt-6 mt-md-0
          "
        >
          <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 6.00004H13M5.66667 10.6667L1 6.00004L5.66667 10.6667ZM1 6.00004L5.66667 1.33337L1 6.00004Z"
              stroke="#717171"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="ml-2">
            {{ $t('booking.back_to_experience_details') }}
          </span>
        </router-link>
        <div
          class="
            d-flex
            flex-column flex-md-row
            align-md-end
            justify-space-between
            booking-title
          "
        >
          <div class="booking-title-text mt-3 mb-3 mb-md-0">
            {{ $t('booking.booking_text') }}
          </div>
          <div class="pb-md-0 pb-1">
            <ProgressIndicatorCheckoutContainer
              :steps="steps"
              @clear-order-created="orderCreated = false"
            />
          </div>
        </div>
      </div>
      <v-divider class="my-3 px-5 px-md-0" />
    </template>
    <template
      v-if="
        $route.name != 'booking-payment-status' && $vuetify.breakpoint.lgAndUp
      "
    >
    </template>
    <v-alert
      v-if="errorMessage"
      type="error"
      transition="bounceInRight"
      class="mt-5"
    >
      {{ errorMessage }}
    </v-alert>
    <router-view
      ref="travelerForm"
      :loading="loading"
      @clear-order-created="orderCreated = false"
      @validate="onClickProceedToPayment"
      @validate-booking="bookingValidated = $event"
      @loading-booking="
        (status) => {
          bookingLoading = status;
        }
      "
      @is-valid="buttonEnabled = $event"
    ></router-view>
    <template v-slot:summary>
      <v-btn
        v-if="showConfirmTopButton"
        @click="onConfirmPaymentClick"
        :loading="bookingLoading"
        :disabled="!bookingValidated"
        data-cy="confirm-and-pay"
        block
        class="primary size16-weight700 mt-6 mb-8"
        justify="end"
        depressed
        :height="58"
      >
        {{ $t('product.confirm_and_pay') }}
        {{ amount.value | currency(amount.baseCurrency) }}
        {{ amount.baseCurrency }}
      </v-btn>
      <OrderSummaryContainer />
    </template>
    <template v-slot:progress v-if="!$vuetify.breakpoint.mdAndDown">
      <div class="d-flex flex-column order-info">
        <div class="order-info_title">
          {{ $t('booking_details') }}
        </div>
        <v-btn
          v-if="hasOrderOption && $route.name === 'booking-form'"
          class="primary size16-weight700 mt-6 mb-8"
          justify="end"
          depressed
          :height="58"
          data-cy="go-to-payment"
          :loading="loading"
          :disabled="!buttonEnabled"
          @click="onClickProceedToPayment"
        >
          {{ $t('proceed_to_payment') }}
        </v-btn>
      </div>
    </template>
    <template v-if="showConfirmBottomButton">
      <div class="px-5">
        <v-btn
          @click="onConfirmPaymentClick"
          :loading="bookingLoading"
          :disabled="!bookingValidated"
          data-cy="confirm-and-pay"
          block
          class="primary size16-weight700 mt-6 mb-8"
          justify="end"
          depressed
          :height="58"
        >
          {{ $t('product.confirm_and_pay') }}
          {{ amount.value | currency(amount.baseCurrency) }}
          {{ amount.baseCurrency }}
        </v-btn>
      </div>
    </template>
  </CheckoutLayout>
</template>

<script>
import ProductDetailsMixins from './mixins/ProductDetailsMixins';
import OrderSummaryContainer from './features/OrderSummary/OrderSummaryContainer';
import ProgressIndicatorCheckoutContainer from './features/ProgressIndicator/ProgressIndicatorCheckoutContainer';
import CheckoutLayout from '@/modules/product/features/CheckoutLayout/CheckoutLayout';
import ProductOrderInformationMixins from '@/modules/product/booking/mixins/ProductOrderInformationMixins';
import BookingOrder from '@/modules/product/models/BookingOrder';
import ProductOrderMixins from '@/modules/product/mixins/ProductOrderMixins';
import QuestionsMixins from '@/modules/product/mixins/QuestionsMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'ProductCheckoutPage',
  components: {
    OrderSummaryContainer,
    ProgressIndicatorCheckoutContainer,
    CheckoutLayout,
  },
  mixins: [
    QuestionsMixins,
    ProductOrderMixins,
    ProductDetailsMixins,
    ProductOrderInformationMixins,
    GoogleTagManagerMixins,
  ],

  data() {
    return {
      orderCreated: false,
      steps: [
        {
          name: this.$t('product.steps.names.traveller_details'),
          status: 'PENDING',
          tag: 'booking-form',
        },
        {
          name: this.$t('product.steps.names.review_payment'),
          status: 'PENDING',
          tag: 'booking-payment',
        },
      ],
      loading: false,
      errorMessage: null,
      bookingValidated: false,
      bookingLoading: false,
      buttonEnabled: false,
    };
  },

  watch: {
    orderCreation(val) {
      if (!!val && !!val.error && !val.response) {
        this.loading = false;
        this.errorMessage = val.error;
      } else if (!!val && !!val.response && !this.orderCreated) {
        this.loading = false;
        this.errorMessage = null;
        this.proceedToPayment(this.localProductOrderId);
      }
    },
  },

  computed: {
    backRouteName() {
      const states = {
        payment: 'booking-traveler',
        traveler: 'booking-availability',
        availability: 'booking-details',
      };
      const routeName = this.$route.name;
      return states[routeName] || '-1';
    },

    backLinkText() {
      return this.hasDetails ? this.productDetails.value.title : '';
    },

    showConfirmTopButton() {
      return (
        this.$route.name === 'booking-payment' &&
        !this.$vuetify.breakpoint.mdAndDown
      );
    },

    showConfirmBottomButton() {
      return (
        this.$route.name === 'booking-payment' &&
        this.$vuetify.breakpoint.mdAndDown
      );
    },
  },

  methods: {
    onConfirmPaymentClick() {
      this.$refs.travelerForm.onPaymentButtonClick();
    },

    onClickProceedToPayment() {
      this.$refs.travelerForm.validate();
      this.verifyAnswers(
        this.orderInformation.answers,
        this.productQuestions.value
      );
      this.loading = true;
      this.pushCheckoutAnalytics(this.productId, 2, 'booking');
      this.clearOrderState();
      this.transformAndCreateOrder();
    },

    transformAndCreateOrder(
      travelerId = this.travelerId,
      productId = this.productId
    ) {
      const { amount, selectedPasses, answers } = this.orderInformation;
      let order = null;
      try {
        order = new BookingOrder({
          amount,
          selectedPasses,
          answers,
          travelerId,
          productId,
        });
      } catch (error) {
        this.loading = false;
        this.errorMessage = error.toString();
      } finally {
        if (order) {
          this.setOrderProductId(this.productId);
          this.createOrder(order, travelerId);
        }
      }
    },

    proceedToPayment(localOrderId) {
      this.orderCreated = true;
      this.$router.push({
        name: 'booking-payment',
        params: {
          localOrderId,
        },
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.back-link {
  color: var(--v-grey9-base);
  text-decoration: none;
}

.order-info {
  width: 100%;

  &_title {
    padding-bottom: 6px;
    @include font-size(24, 30, 300);

    &-separator {
      margin-top: 6px !important;
    }
  }
}

.booking {
  &-header {
    padding: 0 20px;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      padding: 0;
    }

    .product-main--spaced & {
      padding: 0;
    }
  }

  &-title {
    &-text {
      @include font-size(36, 150, 300);

      @media (max-width: map-get($grid-breakpoints, 'md')) {
        @include font-size(24, 150, 300);
      }
    }
  }

  &-return-link {
    @include font-size(14, 130, 400);
    text-decoration: none;
    color: inherit;
  }
}
</style>
