<template>
  <div class="icon-wrapper">
    <template
      v-if="
        step.name == $t('product.steps.names.traveller_details') ||
          step.name == $t('product.steps.names.contact_details')
      "
    >
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
            M3.2675 13.3367C5.01618 12.3483 6.99132 11.8303 9 11.8333C11.0833 11.8333 13.0392 12.3792 14.7325
            13.3367M11.5 6.83333C11.5 7.49637 11.2366 8.13226 10.7678 8.6011C10.2989 9.06994 9.66304 9.33333
            9 9.33333C8.33696 9.33333 7.70107 9.06994 7.23223 8.6011C6.76339 8.13226 6.5 7.49637 6.5 6.83333C6.5
            6.17029 6.76339 5.53441 7.23223 5.06557C7.70107 4.59673 8.33696 4.33333 9 4.33333C9.66304 4.33333
            10.2989 4.59673 10.7678 5.06557C11.2366 5.53441 11.5 6.17029 11.5 6.83333ZM16.5 8.5C16.5 9.48491
            16.306 10.4602 15.9291 11.3701C15.5522 12.2801 14.9997 13.1069 14.3033 13.8033C13.6069 14.4997
            12.7801 15.0522 11.8701 15.4291C10.9602 15.806 9.98491 16 9 16C8.01509 16 7.03982 15.806 6.12987
            15.4291C5.21993 15.0522 4.39314 14.4997 3.6967 13.8033C3.00026 13.1069 2.44781 12.2801 2.0709
            11.3701C1.69399 10.4602 1.5 9.48491 1.5 8.5C1.5 6.51088 2.29018 4.60322 3.6967 3.1967C5.10322
            1.79018 7.01088 1 9 1C10.9891 1 12.8968 1.79018 14.3033 3.1967C15.7098 4.60322 16.5 6.51088 16.5 8.5Z
          "
          :stroke="
            isActive ? $vuetify.theme.themes.light.primary.base : '#858F9D'
          "
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>
    <template v-if="step.name == $t('product.steps.names.review_payment')">
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
            M1.5 5.83366H16.5H1.5ZM4.83333 10.0003H5.66667H4.83333ZM9 10.0003H9.83333H9ZM4 13.3337H14C14.663
            13.3337 15.2989 13.0703 15.7678 12.6014C16.2366 12.1326 16.5 11.4967 16.5 10.8337V4.16699C16.5
            3.50395 16.2366 2.86807 15.7678 2.39923C15.2989 1.93038 14.663 1.66699 14 1.66699H4C3.33696 1.66699
            2.70107 1.93038 2.23223 2.39923C1.76339 2.86807 1.5 3.50395 1.5 4.16699V10.8337C1.5 11.4967 1.76339
            12.1326 2.23223 12.6014C2.70107 13.0703 3.33696 13.3337 4 13.3337Z
          "
          :stroke="
            isActive ? $vuetify.theme.themes.light.primary.base : '#858F9D'
          "
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ProgressIndicatorCheckout',
  props: {
    step: {
      default: function() {
        return {
          status: 'PENDING',
          name: this.$t('product.steps.names.traveller_details'),
        };
      },
      type: Object,
      validator: (val) => {
        return ['PENDING', 'DONE', 'ACTIVE'].indexOf(val.status) > -1;
      },
    },
  },
  computed: {
    isActive() {
      return this.step.status === 'ACTIVE';
    },
    isDone() {
      return this.step.status === 'DONE';
    },
    isActiveOrDone() {
      return this.step.isActive || this.isDone;
    },
  },
  watch: {
    step: {
      handler() {
        if (
          (this.step.name === 'Traveller Details' ||
            this.step.name === 'Contact Details') &&
          this.isDone
        ) {
          this.$emit('is-done');
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: flex;
  width: 17px;
  height: 17px;
}
</style>
