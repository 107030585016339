<template>
  <v-skeleton-loader
    type="list-item, list-item, date-picker-days"
    :loading="loading"
    class="options-calendar-wrapper"
  >
    <v-date-picker
      cy-data="options-date-picker"
      v-model="selectedDate"
      flat
      full-width
      no-title
      :allowed-dates="isAllowedDate"
      :picker-date.sync="pickerDate"
      color="primary"
      @change="availabilityDateSelected"
      :locale="userPreferredLanguage"
    ></v-date-picker>
  </v-skeleton-loader>
</template>
<script>
import LanguageMixins from '@/core/mixins/LanguageMixins';
import moment from 'moment';
import ProductAvailabilityMixins from '@/modules/product/booking/mixins/ProductAvailabilityMixins';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';

export default {
  name: 'OptionsDatePicker',
  mixins: [
    LanguageMixins,
    ProductAvailabilityMixins,
    SelectedContextFlightMixins,
  ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedDate: '',
      pickerDate: '',
    };
  },
  created() {
    this.selectedDate = this.selected
      ? moment(this.selected)
          .format('YYYY-MM-DD')
          .toString()
      : moment(this.arrivalTime)
          .format('YYYY-MM-DD')
          .toString();

    this.pickerDate = this.selected
      ? moment(this.selected)
          .format('YYYY-MM')
          .toString()
      : this.arrivalTime
      ? moment(this.arrivalTime)
          .format('YYYY-MM')
          .toString()
      : moment()
          .format('YYYY-MM')
          .toString();
  },
  watch: {
    selected(val) {
      this.pickerDate = !!val
        ? moment(val)
            .format('YYYY-MM')
            .toString()
        : !!this.arrivalTime
        ? moment(this.arrivalTime)
            .format('YYYY-MM')
            .toString()
        : new moment().format('YYYY-MM').toString();
    },
    pickerDate(val) {
      this.updateAllowedDatesByMonth(val);
    },
    selectedContextFlight: {
      immediate: true, // this will make it gets called on the first initilization which is equivalent to making the call in the mounted hook
      handler() {
        this.setSelectedDate();
      },
    },
  },
  methods: {
    availabilityDateSelected(val) {
      this.$emit('availabilityDateSelected', val);
    },
    isAllowedDate(val) {
      const valMonth = moment(val)
        .format('YYYY-MM')
        .toString();
      if (
        this.productAvailability.value &&
        this.productAvailability.value[valMonth]
      ) {
        const currentDate = moment()
          .format('YYYY-MM-DD')
          .toString();
        return (
          val >= currentDate &&
          this.productAvailability.value[valMonth].some((x) => x.date == val)
        );
      } else {
        return false;
      }
    },
    updateAllowedDatesByMonth(newMonthValue) {
      if (newMonthValue) {
        if (!this.productAvailability.value) {
          this.loadAvailability({
            startDate: newMonthValue.concat('-01'),
          });
        } else if (!this.productAvailability.value[newMonthValue]) {
          this.loadAvailability({
            startDate: newMonthValue.concat('-01'),
          });
        }
      }
    },
    setSelectedDate() {
      let today = moment(new Date()).format('YYYY-MM-DD');
      let scheduleDate;
      let scheduleMonth;
      if (this.arrivalTime) {
        scheduleDate = moment(this.arrivalTime)
          .format('YYYY-MM-DD')
          .toString();
        if (scheduleDate >= today) {
          if (this.isAllowedDate(scheduleDate)) {
            scheduleMonth = moment(this.arrivalTime)
              .format('YYYY-MM')
              .toString();
            this.selectedDate = scheduleDate;
            this.pickerDate = scheduleMonth;
            this.availabilityDateSelected(this.selectedDate);
          } else {
            this.selectedDate = '';
            this.$emit('availabilityDateSelected', '');
          }
        }
      }
    },
  },
  updated() {
    this.$nextTick(function() {
      if (!this.selectedDate) {
        this.setSelectedDate();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
::v-deep.options {
  &-calendar {
    &-wrapper {
      .v-date-picker-table {
        height: auto;
        padding-top: 10px;

        table {
          border-collapse: collapse;

          thead {
            border-bottom: 1px solid #e4e8ef;
          }

          tbody {
            &::before {
              content: '_';
              display: block;
              line-height: 1px;
              color: white;
            }

            & > tr {
              & > td {
                padding: 4px;
              }
            }
          }
        }

        .v-btn {
          font-size: 16px;
          font-weight: 600;
          color: var(--v-grey7-base);

          &--active {
            color: #fff !important;
          }
        }
      }
    }
  }
}
</style>
