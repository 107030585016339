<template>
  <SignInCtaCard v-if="enableLoyalty" />
</template>

<script>
import SignInCtaCard from '@/modules/common/components/SignInCtaCard';
import LoyaltyMixins from '@/modules/common/mixins/LoyaltyMixins';
export default {
  name: 'SignInCtaCardContainer',
  components: { SignInCtaCard },
  mixins: [LoyaltyMixins],
};
</script>

<style scoped></style>
