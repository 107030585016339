<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <PDSectionTitle
          :title="$t('product.menu_names.health_safety_measures')"
        />
      </v-col>
      <v-col cols="12">
        <h3 class="safetyMeasures-subtitle">
          {{ $t('product.safety.health_safety_message') }}
        </h3>
        <template v-if="includes.length">
          <ul class="includes mt-3 safetyMeasures-list">
            <li
              class="safetyMeasures-item"
              v-for="(option, idx) in includes"
              :key="idx"
            >
              <span class="ml-2">
                {{ option }}
              </span>
            </li>
          </ul>
        </template>
        <p class="safetyMeasures-caution-text">
          {{ $t('product.safety.safety_precautions_info') }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PDSectionTitle from '@/modules/product/booking/features/Details/ProductDetailsSections/PDSectionTitle';
import ProductDetailsSafetyMeasuresMixins from '@/modules/product/booking/mixins/ProductDetailsSafetyMeasuresMixins';

export default {
  name: 'PDHealthSafety',
  components: { PDSectionTitle },
  mixins: [ProductDetailsSafetyMeasuresMixins],

  props: {
    hasModel: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    includes() {
      const includedOptions = [];
      if (!!this.hasIntensifiedCleaning) {
        includedOptions.push(this.$t('product.safety.intensified_cleaning'));
      }
      if (!!this.hasSocialDistancing) {
        includedOptions.push(
          this.$t('product.safety.social_distancing_enforced')
        );
      }
      if (!!this.faceMask) {
        includedOptions.push(this.$t('product.safety.face_masks'));
      }
      if (!!this.hasReducedCapacity) {
        includedOptions.push(this.$t('product.safety.has_reduced_capacity'));
      }
      if (!!this.hasHandSanitizer) {
        includedOptions.push(this.$t('product.safety.has_hand_sanitizer'));
      }
      if (!!this.hasTemperatureChecks) {
        includedOptions.push(this.$t('product.safety.has_temperature_checks'));
      }
      if (!!this.onlineTicketingOnly) {
        includedOptions.push(this.$t('product.safety.online_ticketing_only'));
      }
      if (!!this.limitedDuration) {
        includedOptions.push(this.$t('product.safety.has_limited_duration'));
      }
      if (!!this.waitingTime) {
        includedOptions.push(this.$t('product.safety.has_waiting_time'));
      }
      if (!!this.otherSafetyInfo) {
        includedOptions.push(this.$t('product.safety.has_other_safety_info'));
      }
      return includedOptions;
    },
  },
};
</script>

<style scoped lang="scss">
.safetyMeasures {
  padding-bottom: 80px;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    margin: 0 0 60px;
  }

  &-title {
    @include font-size(24, 150, 600);
    color: var(--v-grey9-base);
  }

  &-subtitle {
    color: var(--v-grey8-base);
    @include font-size(16, 130, 500);
  }

  &-caution-text {
    margin-top: 24px;
    margin-bottom: 0;
    color: #717171;
    @include font-size(14, 130, 400);
  }

  &-list {
    list-style: none;
  }

  &-item {
    &__title {
      color: #05090f;
      @include font-size(14, 130, 600);
    }

    &:not(&:last-of-type) {
      margin-bottom: 9.5px !important;
    }
  }
}
</style>
