<template>
  <v-card :loading="loading" flat>
    <v-container class="justify-center pa-0">
      <v-row no-gutters>
        <v-col cols="12">
          <template v-if="hasModel">
            <quantity-field-widget
              v-for="pass in internalPasses"
              :key="pass.id"
              class="pa-3 px-5 adv-quantity-field"
              :id="pass.id"
              :title="pass.name"
              :subTitle="pass.description"
              :value="0"
              :max="pass.max"
              :min="pass.min"
              :price="getPrice(pass.price)"
              :valueSupportText="getFormattedPrice(pass.price)"
              @input="$emit('passChange', { id: pass.id, value: $event })"
              :passError="passError"
            />
          </template>

          <template v-else>
            <p class="error-text mb-0 pa-4">
              {{ error }}
            </p>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import QuantityFieldWidget from '@/core/components/QuantityFieldWidget';

export default {
  name: 'PassTypesWidget',
  components: {
    QuantityFieldWidget,
  },
  props: {
    passes: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
    passError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    typePass: {
      type: String,
      default: null,
    },
    minTravelers: [String, Number],
  },

  data() {
    return {
      internalPasses: this.passes,
    };
  },

  computed: {
    loading() {
      return this.isLoading ? 'accent' : false;
    },

    hasModel() {
      return this.passes && this.passes?.length > 0 && !this.passError;
    },
  },

  methods: {
    getFormattedPrice(price) {
      let formattedPrice = 0;
      if (price) {
        formattedPrice = this.$options.filters.currency(
          price.value,
          price.baseCurrency
        );
      } else {
        formattedPrice = 0;
      }

      return `${formattedPrice} / ${this.typePass}`;
    },

    getPrice(price) {
      return price ? price.value : 0;
    },
  },

  watch: {
    passes: {
      deep: true,
      handler(newPasses) {
        this.internalPasses = newPasses;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.adv-quantity-field {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 88%;
    background: #e4e8ef;
  }

  &:last-child::before {
    display: none;
  }
}

.error-text {
  letter-spacing: 0.25px;
  @include font-size(20, 160, 500);
}
</style>
