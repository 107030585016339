<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-list color="transparent" flat class="py-0">
          <v-list-item-group
            v-model="currentSelectedOptionId"
            :mandatory="selected"
            class="options-list"
          >
            <v-list-item
              v-for="option in availabilityOptionsByDate"
              class="px-5"
              :key="option.id"
              :value="option.id"
              @click="selectItem(option)"
              data-cy="booking-option"
            >
              <v-list-item-content class="options-list-item">
                <v-list-item-title
                  v-text="option.optionLabel"
                  class="text-wrap options-list-item-title pr-2"
                />
                <div class="options-list-item-input">
                  <label class="checkcontainer">
                    <input
                      type="radio"
                      :checked="
                        option.id === currentSelectedOptionId ||
                          getAvailabilityLength === 1
                      "
                    />
                  </label>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'OptionsList',
  props: {
    current: {
      type: String,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      currentSelectedOptionId: this.current,
      availabilityOptionsByDate: this.list,
    };
  },

  methods: {
    selectItem(option) {
      this.$emit('selectItem', option);
    },
  },

  computed: {
    getAvailabilityLength() {
      return this.availabilityOptionsByDate?.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkcontainer input {
  width: 18px;
  height: 16px;
}

::v-deep.options {
  &-list {
    & > div:last-child > div::before {
      display: none;
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 0;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 100%;
        background: #e4e8ef;
      }

      &-title {
        flex-basis: 90%;
        @include font-size(14, 150, 700);
      }

      &-input {
        display: flex;
        justify-content: flex-end;
        flex-basis: 10%;
        padding-top: 2px;
      }
    }
  }
}
</style>
