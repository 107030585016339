// TODO component not used

<template>
  <v-container class="pa-0">
    <v-card flat tile :elevation="0">
      <v-img
        class="white--text align-end"
        :alt="`thumbnail for ${title}`"
        :src="thumbnail"
        :max-height="maxHeight"
        :aspect-ratio="aspectRatio"
      >
        <template v-if="enableLoyalty">
          <PointsRewardComponent
            @show="toggleModal"
            :points-value-text="pointsValue"
          />
          <PointsRewardModal @hide="toggleModal" :isModalOpen="isModalActive" />
        </template>
      </v-img>
    </v-card>

    <h3 class="truncate title-text mb-0 pt-2">
      {{ title }}
    </h3>
    <p class="price-text mb-0">
      <span class="d-inline">
        {{ $t('from') }}
        <span class="amount">
          {{ priceValue | currency(priceCurrency) }}
        </span>
        {{ $t('common.per_person') }}
      </span>
    </p>
  </v-container>
</template>

<script>
import PointsRewardComponent from './PointsRewardComponent';
import PointsRewardModal from '@/modules/common/components/PointsRewardModal';

export default {
  name: 'ProductItemComponent',
  components: { PointsRewardComponent, PointsRewardModal },
  props: {
    title: String,
    thumbnail: String,
    pointsValue: Number,
    priceValue: Number,
    priceCurrency: String,
    maxHeight: {
      type: Number,
      default: 220,
    },
    aspectRatio: {
      type: Number || String,
      default: 1.5,
    },
  },

  data() {
    return { isModalActive: false };
  },

  computed: {
    enableLoyalty() {
      return this.$store.getters['featureFlags/enableLoyalty'];
    },
  },

  methods: {
    toggleModal() {
      this.isModalActive = !this.isModalActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  color: #000000;
  font-weight: bold;
  font-size: 18px;
}
.price-text {
  color: #000000;
  font-size: 14px;
  font-weight: normal;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.amount {
  font-weight: bold;
}
</style>
