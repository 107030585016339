<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="$vuetify.breakpoint.mdAndDown">
          <OrderSummaryExpansion
            :orderItems="orderItems"
            :discount="discount"
            :total="total"
            :totalEarningPoints="totalEarningPoints"
            :pointsCurrency="pointsCurrency"
            :imgUrls="imgUrls"
            :title="title"
            :loading="isLoading"
            :notes="notes"
            :selectedDate="selectedDate"
            :ticket="ticket"
            :currency="baseCurrency"
          />
        </template>

        <template v-else>
          <OrderSummary
            :orderItems="orderItems"
            :discount="discount"
            :total="total"
            :totalEarningPoints="totalEarningPoints"
            :pointsCurrency="pointsCurrency"
            :imgUrls="imgUrls"
            :title="title"
            :loading="isLoading"
            :notes="notes"
            :selectedDate="selectedDate"
            :ticket="ticket"
            :currency="baseCurrency"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderSummary from './OrderSummary';
import OrderSummaryExpansion from './OrderSummaryExpansion';
import moment from 'moment';
import ProductDetailsMixins from '@/modules/product/booking/mixins/ProductDetailsMixins';
import ProductOrderInformationMixins from '@/modules/product/booking/mixins/ProductOrderInformationMixins';
import ProductPassesMixins from '@/modules/product/booking/mixins/ProductPassesMixins';
import ProductOrderMixins from '@/modules/product/mixins/ProductOrderMixins';
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins';

export default {
  name: 'OrderSummaryContainer',
  components: { OrderSummary, OrderSummaryExpansion },
  mixins: [
    ProductDetailsMixins,
    ProductOrderInformationMixins,
    ProductPassesMixins,
    ProductOrderMixins,
    ProductCheckoutMixins,
  ],

  computed: {
    imgUrls() {
      if (this.hasDetails && this.productDetails.value.imageUrls) {
        return this.productDetails.value.imageUrls.map((x) =>
          this.enhancedImageUrl(x)
        );
      }
      return [require('@/assets/placeholder-image.jpg')];
    },

    title() {
      return this.hasDetails ? this.productDetails.value.title : '';
    },

    isLoading() {
      return !this.hasDetails;
    },

    selectedPasses() {
      return this.orderInformation.selectedPasses || [];
    },

    orderItems() {
      const orderItems = [];
      this.selectedPasses.forEach((pass) => {
        for (let index in this.productPasses.value) {
          const passDetails = this.productPasses.value[index];
          const { earningValue } = passDetails;
          let earningValuePoints = 0;
          let earningValueCurrency = '';
          if (earningValue) {
            earningValuePoints = earningValue.value;
            earningValueCurrency = earningValue.baseCurrency;
          }

          if (passDetails.id === pass.id) {
            orderItems.push({
              title: passDetails.title,
              quantity: pass.value,
              price: passDetails.price.value,
              currency: passDetails.price.baseCurrency,
              earningValue: earningValuePoints,
              earningValueCurrency: earningValueCurrency,
            });
            break;
          }
        }
      });
      return orderItems;
    },

    total() {
      if (!this.discount) {
        return this.orderItems.reduce(
          (prev, current) => prev + current.price * current.quantity,
          0
        );
      }
      return this.discount.finalTotal;
    },

    baseCurrency() {
      if (this.hasDetails && this.productDetails.value.priceStartingAt)
        return this.productDetails.value.priceStartingAt.baseCurrency;
      else return null;
    },

    totalEarningPoints() {
      return this.orderItems.length > 0
        ? this.orderItems.reduce(
            (prev, current) => prev + current.earningValue * current.quantity,
            0
          )
        : 0;
    },

    pointsCurrency() {
      return this.orderItems.length > 0
        ? this.orderItems[0].earningValueCurrency
        : null;
    },

    notes() {
      if (this.hasDetails && this.productDetails.value.termsAndConditions)
        return [this.productDetails.value.termsAndConditions];
      else return [];
    },

    selectedDate() {
      const date = this.selectedAvailabilityDate;
      if (date) {
        return moment(date)
          .locale('en')
          .format('dddd, MMMM D')
          .toString();
      } else {
        return null;
      }
    },

    ticket() {
      if (!this.hasOrderOption) return null;
      return this.selectedOption.optionLabel;
    },
  },

  methods: {
    enhancedImageUrl(url) {
      return url.split('?')[0];
    },
  },

  watch: {
    total(val) {
      let currency =
        this.orderItems.length > 0 ? this.orderItems[0].currency : null;
      this.updateTotalAmount(val, currency);
    },
  },
};
</script>

<style></style>
