<template>
  <v-expansion-panels v-model="panel" flat class="summary-wrapper">
    <v-expansion-panel>
      <v-expansion-panel-header
        hide-actions
        class="py-3 px-5 d-flex justify-space-between"
      >
        <span class="summary-title">
          {{ $t('show_booking_details') }}
          <v-icon :color="$vuetify.theme.themes.light.primary.base">
            mdi-chevron-down
          </v-icon>
        </span>

        <template v-if="typeof panel == 'undefined'">
          <span class="summary-currency">
            {{ total | currency(currency) }} {{ currency }}
          </span>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container class="pa-0 ma-0 align-center" :class="{ loading }">
          <v-row dense>
            <template v-if="imgUrls && imgUrls.length">
              <v-col cols="auto">
                <v-img
                  :alt="title"
                  :aspect-ratio="1"
                  width="72"
                  class="location-image mr-4"
                  :src="imgUrls[0]"
                />
              </v-col>
            </template>
            <v-col>
              <h5 class="summary-product">{{ title }}</h5>
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <OrderSelections
            :selectedDate="selectedDate"
            :ticket="ticket"
            :person="person"
          />
          <v-divider class="my-4" />
          <OrderItems
            :items="orderItems"
            :discount="discount"
            :currency="currency"
          />
          <v-divider v-if="hasItems" class="my-4" />
          <v-row no-gutters justify="space-between">
            <v-col>
              <h5 class="summary-total-text">{{ $t('total_price') }}:</h5>
            </v-col>
            <v-col cols="auto">
              <span class="summary-total-price">
                {{ total | currency(currency) }}
                {{ currency }}
              </span>
            </v-col>
          </v-row>

          <template v-if="displayLoyalty">
            <v-row no-gutters justify="end">
              <v-col cols="auto">
                <span class="summary-loyalty">
                  {{
                    $t('booking.order_summary.expansion', {
                      totalEarningPoints,
                      pointsCurrency,
                    })
                  }}
                </span>
              </v-col>
            </v-row>
          </template>

          <template v-if="hasNotes">
            <v-row no-gutters class="flex-nowrap py-2">
              <v-col>
                <ul class="notes">
                  <li
                    v-for="(note, index) in notes"
                    :key="index"
                    v-html="note"
                  />
                </ul>
              </v-col>
            </v-row>
            <v-divider class="my-2" />
          </template>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import OrderSummaryMixin from './OrderSummaryMixin';

export default {
  name: 'OrderSummaryExpansion',
  mixins: [OrderSummaryMixin],
};
</script>

<style lang="scss" scoped>
::v-deep.summary {
  &-wrapper {
    box-shadow: 0px 3px 8px rgb(88 98 113 / 15%);

    .v-expansion-panel {
      background: var(--v-grey1-base);
    }

    .v-expansion-panel-content__wrap {
      padding: 0 20px 20px;
    }
  }

  &-title {
    flex-grow: 100;
    color: var(--v-primary-base);
    @include font-size(14, 150, 700);
  }

  &-currency {
    color: var(--v-grey9-base);
    @include font-size(20, 150, 700);
  }

  &-loyalty {
    @include font-size(16, 150, 700);
  }

  &-product,
  &-total-text {
    @include font-size(16, 150, 400);
  }

  &-total-price {
    @include font-size(20, 150, 700);
  }
}

.loading {
  filter: blur(3px);
}

.notes {
  font-size: 14px;
}

.location-image {
  border-radius: 4px;
  border: 1px solid var(--v-grey3-base);
}
</style>
