<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters class="moreInformation">
      <v-col cols="12">
        <PDSectionTitle :title="$t('product.menu_names.more_information')" />
      </v-col>
      <v-col cols="12">
        <v-expansion-panels accordion multiple class="mt-0">
          <template v-for="(faq, i) in moreInformation">
            <v-expansion-panel
              v-if="faq"
              :key="i"
              data-cy="product-details-more-info-faq-item"
            >
              <v-expansion-panel-header>
                <span class="font-weight-medium">
                  {{ labelList(faq.label) }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <glx-text-truncate
                  class="px-5 mt-5 text-subtitle-2"
                  :length="Infinity"
                  :text="faq.value"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PDSectionTitle from './PDSectionTitle';

export default {
  name: 'PDMoreInformation',
  components: { PDSectionTitle },
  props: {
    moreInformation: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      labelList2: '',
    };
  },

  methods: {
    labelList(val) {
      let list = [
        {
          label: 'additional_info',
          title: this.$t('booking.more_information.additional_info'),
        },
        {
          label: 'itinerary',
          title: this.$t('booking.more_information.itinerary'),
        },
        {
          label: 'special_notes',
          title: this.$t('booking.more_information.special_notes'),
        },
        {
          label: 'duration',
          title: this.$t('booking.more_information.duration'),
        },
        {
          label: 'pickup_notes',
          title: this.$t('booking.more_information.pickup_notes'),
        },
      ];

      let tmpArray = list.filter((item) => item.label === val);

      if (tmpArray.length != 0) {
        return tmpArray[0].title;
      } else {
        return val;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.moreInformation {
  .v-expansion-panels--accordion > .v-expansion-panel::after {
    opacity: 0;
  }
}

::v-deep .v-expansion-panels .v-expansion-panel {
  background-color: var(--v-grey1-base);
  border-radius: 0;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  button {
    min-height: 44px;
    padding: 10px 17px;
    color: var(--v-grey9-base);
    @include font-size(16, 150, 700);
  }

  .v-expansion-panel-header__icon {
    width: 25px;

    .v-icon {
      line-height: 1;
    }
  }

  .v-expansion-panel-content__wrap {
    background: #fff;
    padding: 8px 0 0;
  }
}
</style>
