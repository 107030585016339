<script>
import OrderItems from './OrderItems';
import OrderSelections from './OrderSelections';
import LoyaltyMixins from '@/modules/common/mixins/LoyaltyMixins';

export default {
  name: 'OrderSummaryMixin',
  mixins: [LoyaltyMixins],
  components: { OrderItems, OrderSelections },
  props: {
    imgUrls: Array,
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: 'USD',
    },
    orderItems: {
      type: Array,
      default: () => [],
    },
    discount: {
      type: Object,
      default: null,
    },
    total: {
      type: Number,
      default: 0,
    },
    totalEarningPoints: {
      type: Number,
      default: 0,
    },
    pointsCurrency: {
      type: String,
      default: '',
    },
    notes: {
      type: Array,
      default: () => [],
    },
    selectedDate: {
      type: String,
      default: 'Tuesday, December 20',
    },
    ticket: {
      type: String,
      default: 'English • 11:31',
    },
  },

  data() {
    return {
      panel: undefined,
    };
  },

  computed: {
    hasItems() {
      return this.orderItems && this.orderItems.length > 0;
    },

    hasNotes() {
      return this.notes && this.notes.length > 0;
    },

    totalTicketsQuantity() {
      return this.orderItems.reduce((accu, o) => accu + o.quantity, 0);
    },

    person() {
      return this.$t('booking.order_summary.people_counts', {
        totalTicketsQuantity: this.totalTicketsQuantity,
      });
    },

    displayLoyalty() {
      return this.totalEarningPoints > 0 && this.enableLoyalty;
    },
  },
};
</script>
